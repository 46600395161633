import {
  FormatNumberOptions,
  FormattedMessage as FormattedMessage_,
  IntlProvider as IntlProvider_,
  useIntl,
} from "react-intl";
import { PrimitiveType } from "intl-messageformat";
import type enMessages from "./messages/en.json";
import * as t from "io-ts";
import { array, either, nonEmptyArray, option, taskEither } from "fp-ts";
import { TaskEither } from "fp-ts/TaskEither";
import { NonEmptyArray } from "fp-ts/NonEmptyArray";
import {
  bold,
  Children,
  Day,
  linebreak,
  LocalizedString,
  NonNegativeInteger,
  Percentage,
  TextChildren,
  unsafeLocalizedString,
} from "design-system";
import {
  Channel,
  Currency,
  foldTenant,
  MaritalStatus,
  MoneyAmount,
  Month,
  MonthYear,
  Price,
  PriceFrequency,
  Sex,
  SupportedLocales,
  Tenant,
  unsafeNonNegativeInteger,
} from "./globalDomain";
import { constant, flow, identity, pipe } from "fp-ts/function";
import {
  AllCountries,
  AllCitizenships,
  CitizenshipOrOther,
} from "./IdUpload/domain";
import { DocumentType } from "./UploadDocuments/domain";
import {
  CarOwnershipType,
  IncomeSourceExtended,
  OtherDocumentType,
} from "./ClientProfile/domain";
import { Education, HousingType } from "./MortgageDashboard/domain";
import { CPIFeature, InsuranceType } from "./MortgageDashboard/Offer/domain";
import { useAppContext } from "./useAppContext";
import { Option } from "fp-ts/Option";
import { parsePhoneNumber } from "libphonenumber-js";
import {
  Children as ReactChildren,
  HTMLAttributes,
  isValidElement,
  ReactNode,
} from "react";
import { NonResidentsAdditionalDocumentsType } from "./UKontoSecondPart/domain";

export const translationsActive = !window.location
  .toString()
  .includes("translations=false");

export type LocaleMessages = typeof enMessages;

// Represents LocaleKeys coming from BFF. Messages are inside the file "runtime.en.json"
export interface RuntimeLocaleKeyBrand {
  readonly RuntimeLocaleKey: unique symbol;
}

export const RuntimeLocaleKey = t.brand(
  t.string,
  (_): _ is t.Branded<string, RuntimeLocaleKeyBrand> => true,
  "RuntimeLocaleKey"
);

export type RuntimeLocaleKey = t.TypeOf<typeof RuntimeLocaleKey>;
export type LocaleKey = keyof LocaleMessages | RuntimeLocaleKey;

export function useFormatMessage(): (
  id: LocaleKey,
  values?: Record<string, PrimitiveType>
) => LocalizedString {
  const intl = useIntl();

  return (id, values) => {
    if (!translationsActive && values) {
      return unsafeLocalizedString(`${id}-${Object.values(values).join("-")}`);
    }
    return unsafeLocalizedString(intl.formatMessage({ id }, values));
  };
}

export function useFormatRichMessage2(): (
  id: LocaleKey,
  values?: Record<string, PrimitiveType>,
  options?: { useWhitespacePreWrap?: boolean }
) => any {
  const intl = useIntl();
  const translationsActive = !window.location
    .toString()
    .includes("translations=false");

  return (id, values, options) => {
    if (!translationsActive && values) {
      return `${id}-${Object.values(values).join("-")}`;
    }

    const fillPlaceholders = (
      translation: string,
      values: Record<string, PrimitiveType>
    ) => {
      const keys = Object.keys(values);
      keys.forEach(keyItem => {
        let value = values[keyItem];
        translation = translation.replace(
          new RegExp(`{${keyItem}}`, "g"),
          `${value}`
        );
      });

      return translation;
    };

    const message = values
      ? fillPlaceholders(intl.formatMessage({ id }), values)
      : intl.formatMessage({ id });

    return (
      <span
        dangerouslySetInnerHTML={{ __html: message }}
        style={{
          whiteSpace: !!options?.useWhitespacePreWrap ? "pre-wrap" : "normal",
        }}
      />
    );
  };
}

export function useFormatRichMessage(): (
  id: LocaleKey,
  values?: Record<string, PrimitiveType>
) => TextChildren {
  const intl = useIntl();

  return (id, values) => {
    if (!translationsActive && values) {
      return unsafeLocalizedString(`${id}-${Object.values(values).join("-")}`);
    }
    const message = intl.formatMessage(
      { id },
      { ...values, br: <br />, b: (...chunks) => <b>{chunks}</b> }
    );
    return pipe(
      ReactChildren.toArray(message) as NonEmptyArray<ReactNode>,
      nonEmptyArray.map(c => {
        if (typeof c === "string") {
          return unsafeLocalizedString(c);
        }
        if (isValidElement(c) && c.type === "br") {
          return linebreak;
        }
        if (
          isValidElement<HTMLAttributes<HTMLElement>>(c) &&
          c.type === "b" &&
          Array.isArray(c.props.children) &&
          Array.isArray(c.props.children[0]) &&
          typeof c.props.children[0][0] === "string"
        ) {
          return bold(unsafeLocalizedString(c.props.children[0][0]));
        }
        console.log(c);
        throw new Error(`Not a valid TextChildren element`);
      })
    );
  };
}

export function useFormatPriceFrequency(): (
  frequency: PriceFrequency
) => LocalizedString {
  const formatMessage = useFormatMessage();

  return frequency => {
    switch (frequency) {
      case "OneTime":
        return formatMessage("PriceFrequency.oneTime");
      case "PerMonth":
        return formatMessage("PriceFrequency.perMonth");
    }
  };
}

type LocalizedMoneyAmountParts = {
  amount: LocalizedString;
  currency: LocalizedString;
};

export function useFormatMoneyAmountParts(
  fractionDigits?: NonNegativeInteger
): (moneyAmount: MoneyAmount) => LocalizedMoneyAmountParts {
  const intl = useIntl();
  const formatCurrency = useFormatCurrency();
  return moneyAmount => {
    const amount = intl
      .formatNumberToParts(moneyAmount.amount, {
        style: "currency",
        currency: moneyAmount.currency,
        minimumFractionDigits: fractionDigits,
        maximumFractionDigits: fractionDigits,
      })
      .filter(el => el.type !== "currency")
      .map(el => el.value)
      .join("")
      .trim();

    return {
      amount: unsafeLocalizedString(amount),
      currency: formatCurrency(moneyAmount.currency),
    };
  };
}

export function useFormatMoneyAmountValue(
  fractionDigits?: NonNegativeInteger
): (moneyAmount: MoneyAmount) => LocalizedString {
  const {
    apiParameters: { tenant },
  } = useAppContext();

  const formatMoneyAmountParts = useFormatMoneyAmountParts(
    foldTenant(tenant, constant(fractionDigits), () =>
      unsafeNonNegativeInteger(0)
    )
  );
  const formatMessage = useFormatMessage();

  return moneyAmount =>
    formatMessage("MoneyAmount", formatMoneyAmountParts(moneyAmount));
}

export function useFormatPhoneNumber(): (input: string) => string {
  return input => {
    const phoneNumber = option.fromNullable(parsePhoneNumber(input));

    return pipe(
      phoneNumber,
      option.map(phone => {
        if (!phone.isValid()) {
          return input;
        }
        return phone.formatInternational();
      }),
      option.getOrElse(constant(input))
    );
  };
}

export function useFormatMoneyAmount(
  fractionDigits?: NonNegativeInteger
): <A>(
  moneyAmount: MoneyAmount,
  render: (localizedMoneyAmount: LocalizedMoneyAmountParts) => A
) => A {
  const {
    apiParameters: { tenant },
  } = useAppContext();

  const formatMoneyAmountParts = useFormatMoneyAmountParts(
    foldTenant(tenant, constant(fractionDigits), () =>
      unsafeNonNegativeInteger(0)
    )
  );

  return (moneyAmount, render) => render(formatMoneyAmountParts(moneyAmount));
}

export function useFormatPrice(): <A>(
  price: Price,
  whenFree: (localizedPrice: LocalizedString) => A,
  whenNonFree: (localizedPrice: {
    amount: LocalizedString;
    currency: LocalizedString;
    frequency: LocalizedString;
  }) => A
) => A {
  const formatMessage = useFormatMessage();
  const formatFrequency = useFormatPriceFrequency();
  const formatMoneyAmount = useFormatMoneyAmount();

  return (price, whenFree, whenNonFree) => {
    switch (price.type) {
      case "free":
        return whenFree(formatMessage("Price.free"));
      case "amount":
        return whenNonFree({
          ...formatMoneyAmount(price.moneyAmount, identity),
          frequency: formatFrequency(price.frequency),
        });
    }
  };
}

export function useFormatPriceValue(): (price: Price) => LocalizedString {
  const formatPrice = useFormatPrice();
  const formatMessage = useFormatMessage();

  return price =>
    formatPrice(price, identity, ({ amount, currency }) =>
      formatMessage("MoneyAmount", { amount, currency })
    );
}

export function useFormatPercentage(
  locale?: string
): (percentage: Percentage) => LocalizedString {
  const formatMessage = useFormatMessage();
  return percentage =>
    formatMessage("Percentage", {
      value: new Intl.NumberFormat(
        locale !== undefined ? locale : navigator.language
      ).format(percentage * 100),
    });
}

// Like formatPercentage, but can be used with any percentage-like number
// Use it when you may have to yield "101%" or "-25%"
export function useFormatPercentageLike(
  locale?: string
): (percentage: number, opts?: FormatNumberOptions) => LocalizedString {
  const formatMessage = useFormatMessage();
  return (percentage, opts) =>
    formatMessage("Percentage", {
      value: new Intl.NumberFormat(
        locale !== undefined ? locale : navigator.language,
        opts
      ).format(percentage * 100),
    });
}

// Like formatPercentage, but can be used with any percentage-like number
// Use it when you may have to yield "101%" or "-25%"
export function useFormatMessagePercentage(
  locale?: string
): (percentage: number, opts?: FormatNumberOptions) => LocalizedString {
  const formatMessage = useFormatMessage();
  return (percentage, opts) =>
    formatMessage("Percentage", {
      value: new Intl.NumberFormat(
        locale !== undefined ? locale : navigator.language,
        opts
      ).format(percentage),
    });
}

export function FormattedMessage(props: {
  id: LocaleKey;
  values?: Record<string, PrimitiveType>;
}) {
  return <FormattedMessage_ id={props.id} values={props.values} />;
}

export function IntlProvider(
  props: Omit<React.ComponentProps<typeof IntlProvider_>, "messages"> & {
    messages: LocaleMessages;
    children: Children;
  }
) {
  return <IntlProvider_ {...props} />;
}

const browserLocale = pipe(
  navigator.languages[0],
  option.fromNullable,
  option.map(l => l.split("-")[0]),
  option.chain(flow(SupportedLocales.decode, option.fromEither))
);

export const defaultBrowserLocale = () =>
  pipe(browserLocale, option.getOrElse(constant<SupportedLocales>("en")));

export const defaultLocaleFromTenant = (tenant: Tenant): SupportedLocales => {
  switch (tenant) {
    case "CZ":
      return "cs";
    case "SK":
      return "sk";
  }
};

export function localeFromUrl(): Option<SupportedLocales> {
  const match = RegExp("[?&]lang=([^&]*)").exec(window.location.search);
  const lang = match && decodeURIComponent(match[1].replace(/\+/g, " "));

  return pipe(
    QuerystringLanguage.decode(lang),
    either.fold(
      () => option.none,
      v => option.some(querystringLanguageToSupportedLocale(v))
    )
  );
}

export function defaultLocale(tenant: Tenant): SupportedLocales {
  return pipe(
    browserLocale,
    option.filter(l => l === defaultLocaleFromTenant(tenant)),
    option.getOrElse(constant<SupportedLocales>("en"))
  );
}

export const QuerystringLanguage = t.keyof(
  { EN: true, GB: true, US: true, CZ: true, CS: true, SK: true },
  "QuerystringLanguage"
);

export type QuerystringLanguage = t.TypeOf<typeof QuerystringLanguage>;

export function querystringLanguageToSupportedLocale(
  lang: QuerystringLanguage
): SupportedLocales {
  switch (lang) {
    case "EN":
    case "GB":
    case "US":
      return "en";
    case "CZ":
    case "CS":
      return "cs";
    case "SK":
      return "sk";
  }
}

function importMessages_(
  apiEndpoint: string,
  tenant: Tenant,
  locale: SupportedLocales,
  channel: Channel
): Promise<LocaleMessages> {
  return fetch(
    `${apiEndpoint.replace(
      /\/+$/,
      ""
    )}/translations/noauth?component=eShopFE&channel=${channel}`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        Tenant: tenant,
        "Accept-Language": locale,
      },
    }
  ).then(response => response.json());
}

export function importMessages(
  apiEndpoint: string,
  tenant: Tenant,
  locale: SupportedLocales,
  channel: Channel
): TaskEither<string, LocaleMessages> {
  return pipe(
    taskEither.fromIOEither(() =>
      window.location.toString().includes("translations=false")
        ? either.right({} as LocaleMessages)
        : either.left(undefined)
    ),
    taskEither.orElse(() =>
      taskEither.tryCatch(
        () => importMessages_(apiEndpoint, tenant, locale, channel),
        () => `Failed to import locale "${locale}" for tenant "${tenant}"`
      )
    )
  );
}

export function useFormatCurrency(): (
  currency: Currency,
  format?: "long"
) => LocalizedString {
  const formatMessage = useFormatMessage();

  return (currency, format) => {
    switch (currency) {
      case "CZK":
        return formatMessage(
          format === "long" ? "Currency.CZK.long" : "Currency.CZK.short"
        );
      case "EUR":
        return formatMessage(
          format === "long" ? "Currency.EUR.long" : "Currency.EUR.short"
        );
    }
  };
}

export function useFormatIncomeCurrency(): (
  currency: string,
  format?: "long"
) => LocalizedString {
  const formatMessage = useFormatMessage();

  return (code, format) => {
    const currencyTranslation =
      format === "long" ? `Currency.${code}.long` : `Currency.${code}.short`;
    return formatMessage(currencyTranslation as LocaleKey);
  };
}

export function useFormatMonthYearValue() {
  const formatMessage = useFormatMessage();
  // TODO: use proper localized date formatting
  return (monthYear: MonthYear) => formatMessage("MonthYear", monthYear);
}

export function useFormatYearValue() {
  return (monthYear: MonthYear) => (monthYear.year + "") as LocalizedString;
}

export function useFormatMonth() {
  const { locale } = useIntl();
  return (month: Month): LocalizedString => {
    return unsafeLocalizedString(
      new Date(2020, month - 1, 2).toLocaleString(locale, { month: "long" })
    );
  };
}

export const months = pipe(
  array.range(1, 12),
  array.map((n): Month => n as any)
);

export function useFormatDay() {
  return (day: Day): LocalizedString => {
    return unsafeLocalizedString(day);
  };
}

export const days = pipe(
  array.range(1, 31),
  array.map((n): Day => n as any)
);

export function formatDuration(timeSeconds: number) {
  const minutes = Math.floor(timeSeconds / 60);
  const seconds = timeSeconds % 60;

  const formattedTime = [minutes, seconds]
    .map(component => component.toString().padStart(2, "0"))
    .join(":");

  return unsafeLocalizedString(formattedTime);
}

export function useFormatSex() {
  const formatMessage = useFormatMessage();
  return (sex: Sex): LocalizedString => {
    switch (sex) {
      case "M":
        return formatMessage("Identification.personalData.sex.M");
      case "F":
        return formatMessage("Identification.personalData.sex.F");
    }
  };
}

export function useFormatCitizenship() {
  const formatMessage = useFormatMessage();

  return (
    citizenship: AllCitizenships,
    countries?: AllCountries
  ): LocalizedString => {
    switch (citizenship) {
      case "CZE":
        return formatMessage("Identification.personalData.citizenship.CZ");
      case "SVK":
        return formatMessage("Identification.personalData.citizenship.SK");
      default:
        return countries !== undefined
          ? unsafeLocalizedString(
              pipe(
                countries,
                array.findFirst(c => c.countryCode === citizenship),
                option.map(c => c.label),
                option.getOrElse(constant(""))
              )
            )
          : unsafeLocalizedString(citizenship);
    }
  };
}

export function useFormatCitizenshipOrOther() {
  const formatMessage = useFormatMessage();
  const formatCitizenship = useFormatCitizenship();
  return (citizenship: CitizenshipOrOther): LocalizedString => {
    if (citizenship !== "CZE" && citizenship !== "SVK") {
      return formatMessage("Identification.personalData.citizenship.OTHER");
    }

    return formatCitizenship(citizenship);
  };
}

export function useFormatMaritalStatus() {
  const formatMessage = useFormatMessage();

  return (maritalStatus: MaritalStatus): LocalizedString => {
    switch (maritalStatus) {
      case "Single":
        return formatMessage(
          "Identification.personalData.maritalStatus.Single"
        );
      case "Married":
        return formatMessage(
          "Identification.personalData.maritalStatus.Married"
        );
      case "Divorced":
        return formatMessage(
          "Identification.personalData.maritalStatus.Divorced"
        );
      case "Widowed":
        return formatMessage(
          "Identification.personalData.maritalStatus.Widowed"
        );
      case "RegisteredPartnership":
        return formatMessage(
          "Identification.personalData.maritalStatus.RegisteredPartnership"
        );
    }
  };
}

export function useFormatEducation() {
  const formatMessage = useFormatMessage();
  return (education: Education): LocalizedString => {
    switch (education) {
      case "Elementary":
        return formatMessage("Mortgage.AdditionalDetails.Education.elementary");
      case "ElementarySchool":
        return formatMessage(
          "Mortgage.AdditionalDetails.Education.elementarySchool"
        );
      case "Apprenticed":
        return formatMessage(
          "Mortgage.AdditionalDetails.Education.apprenticed"
        );
      case "ApprenticedWithLeavingExam":
        return formatMessage(
          "Mortgage.AdditionalDetails.Education.apprenticedWithLeavingExams"
        );
      case "SecondaryWithLeavingExam":
        return formatMessage(
          "Mortgage.AdditionalDetails.Education.secondaryWithLeavingExams"
        );
      case "College":
        return formatMessage("Mortgage.AdditionalDetails.Education.college");
      case "University":
        return formatMessage("Mortgage.AdditionalDetails.Education.university");
      case "SecondaryWithoutLeavingExam":
        return formatMessage(
          "Mortgage.AdditionalDetails.Education.secondaryWithoutLeavingExams"
        );
      case "UniversityAbroad":
        return formatMessage(
          "Mortgage.AdditionalDetails.Education.universityAbroad"
        );
      case "UniversityInSlovakRepublik":
        return formatMessage(
          "Mortgage.AdditionalDetails.Education.universityInSlovakRepublik"
        );
    }
  };
}

export function useFormatPortalStatusDate() {
  const { locale } = useIntl();
  const dateFormat = (): Intl.DateTimeFormatOptions => {
    return {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    };
  };
  return (date: Date): LocalizedString => {
    return unsafeLocalizedString(
      date.toLocaleString(locale === "en" ? "en-GB" : locale, dateFormat())
    );
  };
}

export function useFormatDate(output: "mm-yyyy" | "dd-mm-yyyy" | "dd-mm-yy") {
  const { locale } = useIntl();

  const dateFormat = (): Intl.DateTimeFormatOptions => {
    switch (output) {
      case "mm-yyyy":
        return { month: "numeric", year: "numeric", timeZone: "GMT" };
      case "dd-mm-yy":
        return {
          month: "numeric",
          day: "numeric",
          year: "2-digit",
          timeZone: "GMT",
        };
      case "dd-mm-yyyy":
        return {
          month: "numeric",
          day: "numeric",
          year: "numeric",
          timeZone: "GMT",
        };
    }
  };
  const dateFormatter = new Intl.DateTimeFormat(
    locale === "en" ? "en-GB" : locale,
    dateFormat()
  );

  return (date: Date): LocalizedString => {
    return unsafeLocalizedString(dateFormatter.format(date));
  };
}

export function useFormatDocumentType() {
  const formatMessage = useFormatMessage();

  return (documentType: DocumentType): LocalizedString => {
    switch (documentType) {
      case "IDCard":
        return formatMessage("Identification.personalData.nationalId");
      case "Passport":
        return formatMessage("Identification.personalData.passport");
      case "DrivingLicense":
        return formatMessage("Identification.personalData.drivingLicense");
      case "LongTermResidencePermit":
        return formatMessage(
          "Identification.personalData.longTermResidencePermit"
        );
      case "PermanentResidencePermit":
        return formatMessage(
          "Identification.personalData.permanentTermResidencePermit"
        );
      case "TemporaryResidencePermit":
        return formatMessage(
          "Identification.personalData.temporaryTermResidencePermit"
        );
    }
  };
}

export function useFormatIncomeSource() {
  const formatMessage = useFormatMessage();

  return (incomeSource: IncomeSourceExtended) => {
    switch (incomeSource) {
      case "Employed":
        return formatMessage("KYC.Income.IncomeSource.Employed");
      case "Entrepreneur":
        return formatMessage("KYC.Income.IncomeSource.Entrepreneur");
      case "Freelancer":
        return formatMessage("KYC.Income.IncomeSource.Freelancer");
      case "Pensioner":
        return formatMessage("KYC.Income.IncomeSource.Pensioner");
      case "Unemployed":
        return formatMessage("KYC.Income.IncomeSource.Unemployed");
      case "MaternityLeave":
        return formatMessage("KYC.Income.IncomeSource.MaternityLeave");
      case "CompanyOwner":
        return formatMessage("KYC.Income.IncomeSource.CompanyOwner");
      case "Student":
        return formatMessage("KYC.Income.IncomeSource.Student");
      case "HomeDuties":
        return formatMessage("KYC.Income.IncomeSource.HomeDuties");
      case "Other":
        return formatMessage("KYC.Income.IncomeSource.Other");
      default:
        return formatMessage(
          `KYC.Transactions.sourceOfIncome.${incomeSource}` as LocaleKey
        );
    }
  };
}

export function useFormatCarOwnership() {
  const formatMessage = useFormatMessage();

  return (carOwnership: CarOwnershipType): LocalizedString => {
    switch (carOwnership) {
      case "DONT_OWN":
        return formatMessage(
          "ClientProfile.clientData.CarOwnershipType.DONT_OWN"
        );
      case "OWN":
        return formatMessage("ClientProfile.clientData.CarOwnershipType.OWN");
      case "OWN_WITH_LEASING_OR_LOAN":
        return formatMessage(
          "ClientProfile.clientData.CarOwnershipType.OWN_WITH_LEASING_OR_LOAN"
        );
      case "COMPANY_CAR":
        return formatMessage(
          "ClientProfile.clientData.CarOwnershipType.COMPANY_CAR"
        );
    }
  };
}

export function useFormatSelectedInsurance() {
  const formatMessage = useFormatMessage();

  return (insuranceType: InsuranceType): LocalizedString => {
    switch (insuranceType) {
      case "A":
      case "B":
      case "C":
        return formatMessage("Mortgage.Offer.Insurance.package", {
          package: insuranceType,
        });
      default:
        return formatMessage("Mortgage.Offer.Insurance.NoPackage");
    }
  };
}

export function useFormatCPIFeature() {
  const formatMessage = useFormatMessage();

  return (feature: CPIFeature): LocalizedString => {
    switch (feature) {
      case "DEATH":
        return formatMessage("CPI.DEATH");
      case "PERMANENT_DISABILITY":
        return formatMessage("CPI.PERMANENT_DISABILITY");
      case "TEMPORARY_DISABILITY":
        return formatMessage("CPI.TEMPORARY_DISABILITY");
      case "LOSS_OF_INCOME":
        return formatMessage("CPI.LOSS_OF_INCOME");
      case "INSURANCE_OF_SERIOUS_DISEASES":
        return formatMessage("CPI.INSURANCE_OF_SERIOUS_DISEASES");
    }
  };
}

export function useFormatHousingType() {
  const formatMessage = useFormatMessage();

  return (housingType: HousingType): LocalizedString => {
    switch (housingType) {
      case "Cooperative":
        return formatMessage(
          "Mortgage.AdditionalDetails.HousingType.cooperative"
        );
      case "Other":
        return formatMessage("Mortgage.AdditionalDetails.HousingType.other");
      case "Own":
        return formatMessage("Mortgage.AdditionalDetails.HousingType.own");
      case "OwnFlat":
        return formatMessage("Mortgage.AdditionalDetails.HousingType.ownFlat");
      case "OwnHouse":
        return formatMessage("Mortgage.AdditionalDetails.HousingType.ownHouse");
      case "Parents":
        return formatMessage("Mortgage.AdditionalDetails.HousingType.parents");
      case "ProvidedByEmployer":
        return formatMessage(
          "Mortgage.AdditionalDetails.HousingType.providedByEmployer"
        );
      case "RentFromHousingAssociation":
        return formatMessage(
          "Mortgage.AdditionalDetails.HousingType.rentFromHousingAssociation"
        );
      case "RentFromIndividual":
        return formatMessage(
          "Mortgage.AdditionalDetails.HousingType.rentFromIndividual"
        );
      case "RentFromState":
        return formatMessage(
          "Mortgage.AdditionalDetails.HousingType.rentFromState"
        );
      case "Rental":
        return formatMessage("Mortgage.AdditionalDetails.HousingType.rental");
    }
  };
}

export function useFormatOtherDocumentLabel() {
  const formatMessage = useFormatMessage();
  return (label: OtherDocumentType) => {
    switch (label) {
      case "PERMANENT_RESIDENCE_PERMIT":
        return formatMessage(
          "ClientProfile.clientData.IDDocument.otherDocument.PERMANENT_RESIDENCE_PERMIT"
        );
      case "TEMPORARY_RESIDENCE_PERMIT":
        return formatMessage(
          "ClientProfile.clientData.IDDocument.otherDocument.TEMPORARY_RESIDENCE_PERMIT"
        );
      case "LONG_TERM_RESIDENCE_PERMIT":
        return formatMessage(
          "ClientProfile.clientData.IDDocument.otherDocument.LONG_TERM_RESIDENCE_PERMIT"
        );
      case "CONFIRMATION_FROM_MATRIKA":
        return formatMessage(
          "ClientProfile.clientData.IDDocument.otherDocument.CONFIRMATION_FROM_MATRIKA"
        );
      case "BIRTH_CERTIFICATE":
        return formatMessage(
          "ClientProfile.clientData.IDDocument.otherDocument.BIRTH_CERTIFICATE"
        );
      case "SCANNED_ID_CARD":
        return formatMessage(
          "ClientProfile.clientData.IDDocument.otherDocument.SCANNED_ID_CARD"
        );
    }
  };
}

export function useFormatNonResidentsAdditionalDocumentsLabel() {
  const formatMessage = useFormatMessage();
  return (label: NonResidentsAdditionalDocumentsType) => {
    switch (label) {
      case "TEMPORARY_RESIDENCE_PERMIT":
        return formatMessage(
          "Identification.additionalDocuments.eu.nonResidents.TEMPORARY_RESIDENCE_PERMIT"
        );
      case "PERMISSION_TO_STUDY":
        return formatMessage(
          "Identification.additionalDocuments.eu.nonResidents.PERMISSION_TO_STUDY"
        );
      case "EMPLOYMENT_CONTRACT_WITH_CLIENT_DATA":
        return formatMessage(
          "Identification.additionalDocuments.eu.nonResidents.EMPLOYMENT_CONTRACT_WITH_CLIENT_DATA"
        );
      case "LEASE_AGREEMENT_SIGNED_ON_CLIENT_NAME":
        return formatMessage(
          "Identification.additionalDocuments.eu.nonResidents.LEASE_AGREEMENT_SIGNED_ON_CLIENT_NAME"
        );
      case "REAL_ESTATE_LAND_REGISTER_EXTRACT":
        return formatMessage(
          "Identification.additionalDocuments.eu.nonResidents.REAL_ESTATE_LAND_REGISTER_EXTRACT"
        );
      case "LONG_TERM_RESIDENCE_PERMIT":
        return formatMessage(
          "Identification.additionalDocuments.nonEu.nonResidents.LONG_TERM_RESIDENCE_PERMIT"
        );
      case "STUDENT_VISA":
        return formatMessage(
          "Identification.additionalDocuments.nonEu.nonResidents.STUDENT_VISA"
        );
      case "LONG_TERM_VISA_90_PLUS":
        return formatMessage(
          "Identification.additionalDocuments.nonEu.nonResidents.LONG_TERM_VISA_90_PLUS"
        );
      case "MARRIAGE_CERTIFICATE":
        return formatMessage(
          "Identification.additionalDocuments.nonResidents.MARRIAGE_CERTIFICATE"
        );
      case "BUSINESS_CERTIFICATE_AUTHORITY":
        return formatMessage(
          "Identification.additionalDocuments.nonResidents.BUSINESS_CERTIFICATE_AUTHORITY"
        );
    }
  };
}
