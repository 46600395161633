import ClientJS from "clientjs";
import { pipe } from "fp-ts/function";
import { TaskEither } from "fp-ts/TaskEither";
import { taskEither } from "fp-ts";
import * as t from "io-ts";

new ClientJS();
const windowClient = new window.ClientJS();

const FingerprintInfoResponse = t.type(
  {
    ip: t.string,
  },
  "FingerprintInfoResponse"
);

function getIP(apiEndpoint: string): TaskEither<unknown, string> {
  return pipe(
    taskEither.tryCatch(
      () =>
        fetch(
          `${apiEndpoint.replace(
            /\/+$/,
            ""
          )}/utilities/fingerprint/noauth/info`,
          {
            method: "POST",
            headers: {
              Accept: "application/json",
            },
          }
        ).then(response => response.json()),
      () => null
    ),
    taskEither.chainEitherKW(FingerprintInfoResponse.decode),
    taskEither.map(r => r.ip)
  );
}

function getClientFingerPrint(ip: string): number {
  const userAgent =
    typeof window !== "undefined" ? window.navigator.userAgent : "";
  const screenPrint = windowClient.getScreenPrint();
  const pluginList = windowClient.getPlugins();
  const fontList = windowClient.getFonts();
  const localStorage = windowClient.isLocalStorage();
  const sessionStorage = windowClient.isSessionStorage();
  const timeZone = windowClient.getTimeZone();
  const language = windowClient.getLanguage();
  const systemLanguage = windowClient.getSystemLanguage();
  const cookies = windowClient.isCookie();
  const canvasPrint = windowClient.getCanvasPrint();

  return windowClient.getCustomFingerprint(
    userAgent,
    screenPrint,
    pluginList,
    fontList,
    String(localStorage),
    String(sessionStorage),
    timeZone,
    language,
    systemLanguage,
    String(cookies),
    canvasPrint,
    ip
  );
}

type FingerPrintState =
  | { type: "none" }
  | { type: "computing" }
  | { type: "computed"; value: number };

let _fingerPrint: FingerPrintState = { type: "none" };

function computeFingerPrint(apiEndpoint: string): void {
  _fingerPrint = { type: "computing" };
  pipe(
    apiEndpoint,
    getIP,
    taskEither.map(getClientFingerPrint),
    taskEither.chain(value =>
      taskEither.fromIO(() => {
        _fingerPrint = { type: "computed", value };
      })
    ),
    taskEither.alt(() =>
      taskEither.fromIO(() => {
        _fingerPrint = { type: "none" };
      })
    )
  )();
}

export function getFingerPrint(apiEndpoint: string): number | null {
  switch (_fingerPrint.type) {
    case "none":
      computeFingerPrint(apiEndpoint);
      return null;
    case "computing":
      return null;
    case "computed":
      return _fingerPrint.value;
  }
}
