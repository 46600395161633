import { useContext } from "react";
import { BuildConfigContext } from "./BuildConfigContext";
import { NonEmptyString } from "io-ts-types/lib/NonEmptyString";
import { constant, pipe } from "fp-ts/function";
import { option } from "fp-ts";

function useBuildConfigContext() {
  const ctx = useContext(BuildConfigContext);

  if (option.isNone(ctx)) {
    throw new Error("BuildConfigContext not provided");
  }

  return ctx.value;
}

export function useAPIEndpoint(): NonEmptyString {
  const { apiEndpoint, apiPort } = useBuildConfigContext();
  const endpoint = pipe(
    apiEndpoint,
    option.map(e =>
      pipe(
        apiPort,
        option.fold(constant(e), constant(e.replace(/(:\d+)?\/?$/, "")))
      )
    ),
    option.getOrElse(
      () => `${window.location.protocol}//${window.location.hostname}`
    )
  );
  const port = pipe(
    apiPort,
    option.fold(
      () => "",
      port => `:${port}`
    )
  );
  return `${endpoint}${port}` as NonEmptyString;
}
