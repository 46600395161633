import { option, taskEither } from "fp-ts";
import { constant, constFalse, pipe } from "fp-ts/function";
import { ClientDataCheck } from "../UploadDocuments/domain";
import { genericError, GenericError } from "../globalDomain";
import { ExtractClientDataCommand } from "../IdUpload/api";
import { hasValidationErrors, mergeExtractDataDocumentsOutput } from "./utils";
import { ExtractedDataResult } from "./types";
import { TaskEither } from "fp-ts/TaskEither";
import { Option } from "fp-ts/Option";
import { useTestEnvironment } from "../Common/useTestEnvironment";

export type CheckClientDataResult = ExtractedDataResult & {
  canUploadAgain: boolean;
  canEdit: boolean;
  showWarning: boolean;
  fraudCheck: Option<ClientDataCheck>;
};

type CheckClientDataError =
  | {
      id: "FraudCheck";
      data: CheckClientDataResult;
    }
  | {
      id: "Abort";
    }
  | {
      id: "UploadAgain";
      documentMismatch: boolean;
    }
  | {
      id: "ValidationError";
    }
  | GenericError;

export function useCheckClientDataResult(
  extractData: ExtractClientDataCommand,
  isPotentialClient?: boolean
): TaskEither<CheckClientDataError, CheckClientDataResult> {
  const isTestEnvironment = useTestEnvironment();

  return pipe(
    extractData,
    taskEither.mapLeft<unknown, CheckClientDataError>(constant(genericError)),
    taskEither.chain(
      ({
        primary,
        secondary,
        result,
        canUploadAgain,
        documentMismatch: isDocumentMismatch,
      }) => {
        const documentMismatch = pipe(
          isDocumentMismatch,
          option.getOrElse(constFalse)
        );
        const documentsHaveValidationErrors = hasValidationErrors(
          primary,
          secondary
        );
        const documentPayload = mergeExtractDataDocumentsOutput(
          primary,
          secondary
        );
        return pipe(
          documentPayload,
          option.fold(
            () =>
              taskEither.left<CheckClientDataError>({
                id: "GenericError",
              }),
            document => {
              switch (result) {
                case "Abort":
                  return taskEither.left<CheckClientDataError>({
                    id: "Abort",
                  });
                case "UploadAgain":
                  return taskEither.left<CheckClientDataError>({
                    id: "UploadAgain",
                    documentMismatch,
                  });
                case "SelfieFraudCheck":
                case "HologramFraudCheck":
                case "HologramAndSelfieFraudCheck":
                  if (isPotentialClient) {
                    return taskEither.left<CheckClientDataError>({
                      id: "UploadAgain",
                      documentMismatch,
                    });
                  } else {
                    return taskEither.left<CheckClientDataError>({
                      id: "FraudCheck",
                      data: {
                        ...document,
                        canUploadAgain,
                        canEdit: false, // what's the right value for this, in this case?
                        showWarning: false, // what's the right value for this, in this case?
                        fraudCheck: option.some(result),
                      },
                    });
                  }
                case "EditData":
                  if (isPotentialClient) {
                    return taskEither.left<CheckClientDataError>({
                      id: "UploadAgain",
                      documentMismatch,
                    });
                  } else {
                    return taskEither.right<
                      CheckClientDataError,
                      CheckClientDataResult
                    >({
                      ...document,
                      canUploadAgain,
                      canEdit: true,
                      showWarning: false,
                      fraudCheck: option.none,
                    });
                  }
                case "Continue":
                  if (documentsHaveValidationErrors && !canUploadAgain) {
                    return taskEither.left<CheckClientDataError>({
                      id: "ValidationError",
                    });
                  }

                  return taskEither.right<
                    CheckClientDataError,
                    CheckClientDataResult
                  >({
                    ...document,
                    canUploadAgain,
                    canEdit: isTestEnvironment,
                    showWarning: false,
                    fraudCheck: option.none,
                  });
                case "ContinueWithWarning":
                  if (documentsHaveValidationErrors && !canUploadAgain) {
                    return taskEither.left<CheckClientDataError>({
                      id: "ValidationError",
                    });
                  }

                  if (isPotentialClient) {
                    return taskEither.left<CheckClientDataError>({
                      id: "UploadAgain",
                      documentMismatch,
                    });
                  } else {
                    return taskEither.right<
                      CheckClientDataError,
                      CheckClientDataResult
                    >({
                      ...document,
                      canUploadAgain,
                      canEdit: false,
                      showWarning: true,
                      fraudCheck: option.none,
                    });
                  }
                case "EditDataWithWarning":
                  if (isPotentialClient) {
                    return taskEither.left<CheckClientDataError>({
                      id: "UploadAgain",
                      documentMismatch,
                    });
                  } else {
                    return taskEither.right<
                      CheckClientDataError,
                      CheckClientDataResult
                    >({
                      ...document,
                      canUploadAgain,
                      canEdit: true,
                      showWarning: true,
                      fraudCheck: option.none,
                    });
                  }
              }
            }
          )
        );
      }
    )
  );
}
