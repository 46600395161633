import { constant, pipe } from "fp-ts/function";
import * as t from "io-ts";
import { NonEmptyString } from "io-ts-types/lib/NonEmptyString";
import { Channel, foldChannelLocation } from "../globalDomain";
import { sharedReducerConfig } from "../BranchExperience/useSharedReducer";
import { ClientDataCheck, MobileRecipientType } from "./domain";

export const MobileFlowType = t.union([
  t.literal("ProofOfIncome"),
  t.literal("DocumentUpload"),
  ClientDataCheck,
]);
export type MobileFlowType = t.TypeOf<typeof MobileFlowType>;

export const MobileRecipientOptionType = t.type({
  key: MobileRecipientType,
  disabled: t.boolean,
  phoneNumber: NonEmptyString,
});
export type MobileRecipientOptionType = t.TypeOf<
  typeof MobileRecipientOptionType
>;

const UploadMode = t.keyof({
  Scanner: true,
  Mobile: true,
  _MockScanner: true,
});
export type UploadMode = t.TypeOf<typeof UploadMode>;

const ReadyState = t.type({ id: t.literal("Ready") });
const ReadyMobileState = t.type({ id: t.literal("ReadyMobile") });
const ChooseUploadModeState = t.type({ id: t.literal("ChooseUploadMode") });
const ScannerUploadState = t.type({
  id: t.literal("ScannerUpload"),
  mocked: t.boolean,
});
const ChooseMobileRecipientState = t.type({
  id: t.literal("ChooseMobileRecipient"),
  flowType: MobileFlowType,
  canUploadAgain: t.boolean,
});
const ChooseRemoteMobileRecipientState = t.type({
  id: t.literal("ChooseRemoteMobileRecipient"),
  flowType: MobileFlowType,
});
const MobileUploadState = t.type({
  id: t.literal("MobileUpload"),
  recipient: MobileRecipientOptionType,
});
const MobileUploadSameDeviceState = t.type({
  id: t.literal("MobileUploadSameDevice"),
});
const MobileFraudCheckSameDeviceState = t.type({
  id: t.literal("MobileFraudCheckSameDevice"),
  check: ClientDataCheck,
});
const UploadSummaryState = t.type({
  id: t.literal("UploadSummary"),
  showUploadCompletedDialog: t.boolean,
  sharedWithClient: t.boolean,
});
const FraudCheckState = t.type({
  id: t.literal("FraudCheck"),
  check: ClientDataCheck,
  recipient: MobileRecipientOptionType,
  canUploadAgain: t.boolean,
});
const UploadAgainState = t.type({
  id: t.literal("UploadAgain"),
  documentMismatch: t.boolean,
});

const State = t.union([
  ReadyState,
  ReadyMobileState,
  ChooseUploadModeState,
  ScannerUploadState,
  ChooseMobileRecipientState,
  ChooseRemoteMobileRecipientState,
  MobileUploadState,
  MobileUploadSameDeviceState,
  MobileFraudCheckSameDeviceState,
  UploadSummaryState,
  FraudCheckState,
  UploadAgainState,
]);
type State = t.TypeOf<typeof State>;

export function foldState<T>(
  state: State,
  match: {
    whenReady: () => T;
    whenReadyMobile: () => T;
    whenChooseUploadMode: () => T;
    whenScannerUpload: (state: Extract<State, { id: "ScannerUpload" }>) => T;
    whenChooseMobileRecipient: (
      state: Extract<State, { id: "ChooseMobileRecipient" }>
    ) => T;
    whenRemoteMobileRecipient: (
      state: Extract<State, { id: "ChooseRemoteMobileRecipient" }>
    ) => T;
    whenMobileUpload: (state: Extract<State, { id: "MobileUpload" }>) => T;
    whenMobileUploadSameDevice: () => T;
    whenMobileFraudCheckSameDevice: (
      state: Extract<State, { id: "MobileFraudCheckSameDevice" }>
    ) => T;
    whenUploadSummary: (state: Extract<State, { id: "UploadSummary" }>) => T;
    whenFraudCheck: (state: Extract<State, { id: "FraudCheck" }>) => T;
    whenUploadAgain: (state: Extract<State, { id: "UploadAgain" }>) => T;
  }
): T {
  switch (state.id) {
    case "Ready":
      return match.whenReady();
    case "ReadyMobile":
      return match.whenReadyMobile();
    case "ChooseUploadMode":
      return match.whenChooseUploadMode();
    case "ScannerUpload":
      return match.whenScannerUpload(state);
    case "ChooseMobileRecipient":
      return match.whenChooseMobileRecipient(state);
    case "ChooseRemoteMobileRecipient":
      return match.whenRemoteMobileRecipient(state);
    case "MobileUpload":
      return match.whenMobileUpload(state);
    case "MobileUploadSameDevice":
      return match.whenMobileUploadSameDevice();
    case "MobileFraudCheckSameDevice":
      return match.whenMobileFraudCheckSameDevice(state);
    case "UploadSummary":
      return match.whenUploadSummary(state);
    case "FraudCheck":
      return match.whenFraudCheck(state);
    case "UploadAgain":
      return match.whenUploadAgain(state);
  }
}

const StartUploadAction = t.type({
  id: t.literal("StartUpload"),
});
export function startUploadAction(): Action {
  return {
    id: "StartUpload",
  };
}

const StartUploadModeAction = t.type({
  id: t.literal("StartUploadMode"),
  payload: UploadMode,
});
export function startUploadModeAction(mode: UploadMode): Action {
  return {
    id: "StartUploadMode",
    payload: mode,
  };
}

const StartMobileUploadFromSameDeviceAction = t.type({
  id: t.literal("StartMobileUploadFromSameDevice"),
});
export const startMobileUploadFromSameDeviceAction: Action = {
  id: "StartMobileUploadFromSameDevice",
};

const StartMobileFraudCheckFromSameDeviceAction = t.type({
  id: t.literal("StartMobileFraudCheckFromSameDevice"),
});
export const startMobileFraudCheckFromSameDeviceAction: Action = {
  id: "StartMobileFraudCheckFromSameDevice",
};

const CancelUploadAction = t.type({
  id: t.literal("CancelUpload"),
  newMobileFlow: t.boolean,
});

export function cancelUploadAction(newMobileFlow: boolean): Action {
  return {
    id: "CancelUpload",
    newMobileFlow,
  };
}

const GoToSummaryAction = t.type({
  id: t.literal("GoToSummary"),
  payload: t.type({
    showUploadCompletedDialog: t.boolean,
  }),
});
export function goToSummaryAction(showUploadCompletedDialog: boolean): Action {
  return {
    id: "GoToSummary",
    payload: { showUploadCompletedDialog },
  };
}

const UploadAgainAction = t.type({
  id: t.literal("UploadAgain"),
  isDocumentMismatch: t.boolean,
});
export function uploadAgainAction(isDocumentMismatch: boolean): Action {
  return {
    id: "UploadAgain",
    isDocumentMismatch: isDocumentMismatch,
  };
}

const StartFraudCheckAction = t.type({
  id: t.literal("StartFraudCheck"),
  payload: t.type({
    fraudCheck: ClientDataCheck,
    canUploadAgain: t.boolean,
  }),
});
export function startFraudCheckAction(
  fraudCheck: ClientDataCheck,
  canUploadAgain: boolean
): Action {
  return {
    id: "StartFraudCheck",
    payload: { fraudCheck, canUploadAgain },
  };
}

const SelectRecipientAction = t.type({
  id: t.literal("SelectRecipient"),
  payload: MobileRecipientOptionType,
});
export function selectRecipientAction(
  recipient: MobileRecipientOptionType
): Action {
  return {
    id: "SelectRecipient",
    payload: recipient,
  };
}

const ShareWithClientAction = t.type({
  id: t.literal("ShareWithClient"),
});
export const shareWithClientAction: Action = {
  id: "ShareWithClient",
};

const Action = t.union([
  StartUploadAction,
  StartUploadModeAction,
  StartMobileUploadFromSameDeviceAction,
  StartMobileFraudCheckFromSameDeviceAction,
  CancelUploadAction,
  GoToSummaryAction,
  UploadAgainAction,
  StartFraudCheckAction,
  SelectRecipientAction,
  ShareWithClientAction,
]);
type Action = t.TypeOf<typeof Action>;

function reducer(channel: Channel) {
  return (state: State, action: Action): State => {
    switch (action.id) {
      case "StartUpload":
        return pipe(
          channel,
          foldChannelLocation<State>({
            Remote: constant({
              id: "ChooseRemoteMobileRecipient",
              flowType: "DocumentUpload",
            }),
            InPerson: () => {
              if (channel === "3P_InPerson") {
                return {
                  id: "ChooseMobileRecipient",
                  flowType: "DocumentUpload",
                  canUploadAgain: false,
                };
              }
              return {
                id: "ChooseUploadMode",
              };
            },
          })
        );
      case "StartUploadMode":
        switch (action.payload) {
          case "Mobile":
            return {
              id: "ChooseMobileRecipient",
              flowType: "DocumentUpload",
              canUploadAgain: false,
            };
          case "Scanner":
            return { id: "ScannerUpload", mocked: false };
          case "_MockScanner":
            return { id: "ScannerUpload", mocked: true };
        }
      case "CancelUpload":
        return { id: action.newMobileFlow ? "ReadyMobile" : "Ready" };
      case "StartFraudCheck":
        return pipe(
          channel,
          foldChannelLocation<State>({
            Remote: constant({
              id: "ChooseRemoteMobileRecipient",
              flowType: action.payload.fraudCheck,
              canUploadAgain: action.payload.canUploadAgain,
            }),
            InPerson: constant({
              id: "ChooseMobileRecipient",
              flowType: action.payload.fraudCheck,
              canUploadAgain: action.payload.canUploadAgain,
            }),
          })
        );
      case "SelectRecipient":
        if (state.id === "ChooseMobileRecipient") {
          if (
            state.flowType === "DocumentUpload" ||
            state.flowType === "ProofOfIncome"
          ) {
            return {
              id: "MobileUpload",
              recipient: action.payload,
            };
          } else {
            return {
              id: "FraudCheck",
              check: state.flowType,
              recipient: action.payload,
              canUploadAgain: state.canUploadAgain,
            };
          }
        } else if (state.id === "ChooseRemoteMobileRecipient") {
          if (
            state.flowType === "DocumentUpload" ||
            state.flowType === "ProofOfIncome"
          ) {
            return {
              id: "MobileUpload",
              recipient: action.payload,
            };
          } else {
            return {
              id: "FraudCheck",
              check: state.flowType,
              recipient: action.payload,
              canUploadAgain: false,
            };
          }
        } else return state;
      case "StartMobileUploadFromSameDevice":
        return { id: "MobileUploadSameDevice" };
      case "StartMobileFraudCheckFromSameDevice":
        if (
          state.id === "ChooseRemoteMobileRecipient" &&
          state.flowType !== "DocumentUpload" &&
          state.flowType !== "ProofOfIncome"
        ) {
          return { id: "MobileFraudCheckSameDevice", check: state.flowType };
        } else return state;
      case "UploadAgain":
        return {
          id: "UploadAgain",
          documentMismatch: action.isDocumentMismatch,
        };
      case "GoToSummary":
        return {
          id: "UploadSummary",
          showUploadCompletedDialog: action.payload.showUploadCompletedDialog,
          sharedWithClient: false,
        };
      case "ShareWithClient":
        if (state.id === "UploadSummary" && !state.sharedWithClient) {
          return { ...state, sharedWithClient: true };
        } else return state;
    }
  };
}

export function getReducerConfig(channel: Channel) {
  return sharedReducerConfig(
    "UploadDocuments",
    State,
    Action,
    reducer(channel)
  );
}
