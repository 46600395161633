import { setupLogging } from "./logger";
import { StrictMode } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "design-system/lib/index.css";
import { InternetApp } from "./InternetApp";
import { FixAndroidScrollInput } from "./Common/FixAndroidScrollInput/FixAndroidScrollInput";

setupLogging();

window.addEventListener("load", e => console.log(e));
window.addEventListener("unload", e => console.log(e));

ReactDOM.render(
  <StrictMode>
    <InternetApp />
    <FixAndroidScrollInput />
  </StrictMode>,
  document.getElementById("root")
);
