import { Loader, Box } from "design-system";
import { boolean, option, record } from "fp-ts";
import { constant, constNull, pipe } from "fp-ts/function";
import * as idUploadApi from "./api";
import { useQuery } from "../useAPI";
import * as remoteData from "../RemoteData";
import { UploadModeDialog } from "./UploadModeDialog";
import { MobileFlowType, UploadMode } from "./state";
import { CoApplicantInput } from "../globalDomain";
import { foldMobileFlowType } from "./utils";
import { LinkPurpose } from "./domain";

type Props = {
  onConfirm: ({ mode }: { mode: UploadMode }) => unknown;
  onDismiss: () => unknown;
  type: MobileFlowType;
} & CoApplicantInput;

export function UploadModeDialogWithMobile(props: Props) {
  const [getMobileRecipients] = useQuery(idUploadApi.mobileRecipients, {
    coApplicant: props.coApplicant,
    linkPurpose: foldMobileFlowType<LinkPurpose>(props.type, {
      DocumentUpload: constant("mobileIdUpload"),
      SelfieFraudCheck: constant("selfieFraudCheck"),
      HologramFraudCheck: constant("hologramFraudCheck"),
      HologramAndSelfieFraudCheck: constant("hologramAndSelfieFraudCheck"),
      ProofOfIncome: constant("proofOfIncome"),
    }),
  });

  return pipe(
    getMobileRecipients,
    remoteData.fold(
      () => (
        <Box hAlignContent="center" vAlignContent="center">
          <Loader />
        </Box>
      ),
      constNull,
      recipients => (
        <UploadModeDialog
          onConfirm={props.onConfirm}
          onDismiss={props.onDismiss}
          mobileFeatureStatus={pipe(
            recipients,
            record.some(r => option.isSome(r.phoneNumber) && !r.disabled),
            boolean.fold(constant("disabled"), constant("enabled"))
          )}
        />
      )
    )
  );
}
