import { ContentRow, Space, Loader } from "design-system";
// eslint-disable-next-line no-restricted-imports
import { useEffect, useState } from "react";
import { CustomCard } from "./CustomCard";
// eslint-disable-next-line no-restricted-imports
import { Banner } from "design-system/lib/vanilla";
import { useFormatMessage } from "../intl";
import { useCommand } from "../useAPI";
import * as ukontoApi from "../UKontoSecondPart/api";
import * as cfApi from "../StandardLoan/api";
import { pipe } from "fp-ts/function";
import { option, taskEither } from "fp-ts";
import { useAppContext } from "../useAppContext";
import { Option } from "fp-ts/Option";

const foldPackageType: <T>(match: {
  whenBasic: () => T;
  whenMiddle: () => T;
  whenUpper: () => T;
}) => (type: string) => T = match => type => {
  switch (type) {
    case "BASIC":
      return match.whenBasic();
    case "MIDDLE":
      return match.whenMiddle();
    case "UPPER":
      return match.whenUpper();
    default:
      return match.whenBasic();
  }
};

type Props = {
  isFromChild?: boolean;
  physicalCardSelected: Option<boolean>;
  onChange: (data: any) => void;
  isCf?: boolean;
  showMastercard: boolean;
};

export const VirtualCards = (props: Props) => {
  const formatMessage = useFormatMessage();
  const initVirtualCard = useCommand(
    props.isCf ? cfApi.initVirtualCard : ukontoApi.getPackageType
  );

  const [loading, setLoading] = useState(true);
  const [packageType, setPackageType] = useState("");
  const [physicalCardSelected, setPhysicalCardSelected] = useState(
    option.some(false)
  );

  const {
    config: {
      virtualCardTopImgURL,
      virtualCardMcTopImgURL,
      virtualCardMcMiddleImgURL,
      virtualCardOpenImgURL,
      virtualCardMcOpenImgURL,
      physicalCardTopImgURL,
      physicalCardMcTopImgURL,
      // physicalCardMiddleImgURL,
      physicalCardMcMiddleImgURL,
      physicalCardOpenImgURL,
      physicalCardMcOpenImgURL,
    },
  } = useAppContext();

  const showMastercard = props.showMastercard;

  useEffect(() => {
    pipe(
      initVirtualCard(),
      taskEither.chain(
        (response: {
          packageType: string;
          physicalCardSelected: Option<boolean>;
        }) =>
          taskEither.fromIO(() => {
            setLoading(false);

            response &&
              response?.packageType &&
              setPackageType(response?.packageType);

            response &&
              response?.physicalCardSelected &&
              setPhysicalCardSelected(response?.physicalCardSelected);
          })
      )
    )();
  }, []);

  const bannerInfo = (
    <Banner
      type="informative"
      content={
        <span
          dangerouslySetInnerHTML={{
            __html: formatMessage("UKonto.MainContent.PhysicalCardBannerInfo"),
          }}
        />
      }
    />
  );

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <ContentRow type="full">
        <CustomCard
          checkBoxValue={option.some(true)}
          checkBoxOnChange={() => {}}
          checkBoxName="virtualCard"
          checkBoxDisabled={true}
          cardTitle={formatMessage(
            "UKonto.MainContent.virtualCardCheckboxTitle"
          )}
          cardDescription={formatMessage(
            "UKonto.MainContent.virtualCardBoxContent"
          )}
          showModalLabel={formatMessage("MoreInfo")}
          physicalCard={false}
          imageCardHorizontal={showMastercard}
          raiseCard={false}
          imgUrl={pipe(
            packageType,
            foldPackageType({
              whenBasic: () =>
                showMastercard
                  ? virtualCardMcOpenImgURL
                  : virtualCardOpenImgURL,
              whenMiddle: () =>
                showMastercard
                  ? virtualCardMcMiddleImgURL
                  : virtualCardOpenImgURL,
              whenUpper: () =>
                showMastercard ? virtualCardMcTopImgURL : virtualCardTopImgURL,
            })
          )}
          dialogTitle={formatMessage(
            "UKonto.MainContent.VirtualCardPopupTitle"
          )}
          dialogContent={
            <>
              <span
                dangerouslySetInnerHTML={{
                  __html: formatMessage(
                    "UKonto.MainContent.VirtualCardPopupContent"
                  ),
                }}
              />
              {bannerInfo}
            </>
          }
          tooltipInfoTitle={formatMessage(
            "UKonto.MainContent.VirtualCardTooltipDescriptionTitle"
          )}
          tooltipInfoContent={formatMessage(
            "UKonto.MainContent.VirtualCardTooltipDescription"
          )}
        />
      </ContentRow>
      <Space units={5} />
      <ContentRow type="full">
        <CustomCard
          checkBoxValue={
            props.isCf ? physicalCardSelected : props.physicalCardSelected
          }
          checkBoxOnChange={(data: any) => {
            setPhysicalCardSelected(data.physicalCardSelected);
            props.onChange(data);
          }}
          checkBoxName="physicalCard"
          checkBoxDisabled={props.isFromChild}
          cardTitle={formatMessage(
            "UKonto.MainContent.physicalCardCheckboxTitle"
          )}
          cardDescription={formatMessage(
            "UKonto.MainContent.physicalCardBoxContent"
          )}
          showModalLabel={formatMessage("MoreInfo")}
          physicalCard={true}
          imageCardHorizontal={showMastercard}
          raiseCard={showMastercard}
          imgUrl={pipe(
            packageType,
            foldPackageType({
              whenBasic: () =>
                showMastercard
                  ? physicalCardMcOpenImgURL
                  : physicalCardOpenImgURL,
              whenMiddle: () =>
                showMastercard
                  ? physicalCardMcMiddleImgURL
                  : physicalCardOpenImgURL,
              whenUpper: () =>
                showMastercard
                  ? physicalCardMcTopImgURL
                  : physicalCardTopImgURL,
            })
          )}
          dialogTitle={formatMessage(
            "UKonto.MainContent.PhysicalCardPopupTitle"
          )}
          dialogContent={
            <>
              <span
                dangerouslySetInnerHTML={{
                  __html: formatMessage(
                    "UKonto.MainContent.PhysicalCardPopupContent"
                  ),
                }}
              />
              {bannerInfo}
            </>
          }
        />
      </ContentRow>
    </>
  );
};
