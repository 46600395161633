import {
  Body,
  bold,
  Box,
  Button,
  Card,
  Children,
  InfoIcon,
  LocalizedString,
  Space,
  Stack,
  Tooltip,
  useIsMobileLayout,
  useIsTouchScreen,
} from "design-system";
// eslint-disable-next-line no-restricted-imports
import { CheckboxField, Dialog } from "design-system/lib/vanilla";
import { palette } from "design-system/lib/styleConstants";
import { useState } from "react";
import { option } from "fp-ts";
import { VirtualCardsDialogChild } from "../Common/Dialogs/VirtualCardsDialog/VirtualCardsDialogChild";
import { Option } from "fp-ts/Option";
import * as classnames from "./CustomCard.treat";

type Props = {
  checkBoxValue?: Option<boolean>;
  checkBoxOnChange: (data: {
    physicalCardSelected: Option<boolean>;
  }) => unknown;
  checkBoxName: string;
  checkBoxDisabled?: boolean;
  cardTitle: LocalizedString;
  cardDescription: LocalizedString;
  showModalLabel: LocalizedString;
  imgUrl: string;
  dialogTitle: LocalizedString;
  dialogContent: Children;
  tooltipInfoTitle?: LocalizedString;
  tooltipInfoContent?: LocalizedString;
  physicalCard: boolean;
  imageCardHorizontal: boolean;
  raiseCard: boolean;
};

export const CustomCard = (props: Props) => {
  const [showModal, setShowModal] = useState(false);
  const isBranchExperienceTouchScreen = useIsTouchScreen();
  const isMobileLayout = useIsMobileLayout();

  const checkBoxValue =
    props.checkBoxValue && option.isSome(props.checkBoxValue)
      ? props.checkBoxValue.value
      : false;

  return (
    <Box grow shrink column>
      <Card hoverHighlighted>
        <Stack units={3} shrink grow fluid>
          <Box column shrink grow>
            <Box shrink vAlignContent="center">
              <CheckboxField
                disabled={!!props.checkBoxDisabled}
                value={checkBoxValue}
                name={props.checkBoxName}
                onChange={(data: { target: any }) =>
                  props.checkBoxOnChange({
                    physicalCardSelected: option.some(data.target.checked),
                  })
                }
                label={bold(props.cardTitle)}
                className={classnames.customCard}
              />
              <>
                {!!props.tooltipInfoContent && !!props.tooltipInfoTitle && (
                  <Tooltip
                    content={[props.tooltipInfoTitle, props.tooltipInfoContent]}
                  >
                    <InfoIcon size="default" color={palette.blue600} />
                  </Tooltip>
                )}
              </>
            </Box>
            <Space units={3} />
            <Box grow shrink>
              <Body
                size="medium"
                weight="regular"
                color={palette.neutral500}
                align="left"
              >
                {props.cardDescription}
              </Body>
              {!isMobileLayout && <Space units={10} />}
            </Box>
            <Box grow shrink vAlignContent="bottom">
              <Button
                variant="text"
                size="default"
                action={() => {
                  setShowModal(true);
                }}
                label={props.showModalLabel}
              />
            </Box>
          </Box>
          <Box
            column
            style={{
              maxWidth: isMobileLayout ? "100px" : "150px",
              justifyContent: props.imageCardHorizontal ? "center" : undefined,
            }}
            grow
            hAlignContent="right"
          >
            <img
              alt=""
              title=""
              src={props.imgUrl}
              style={{
                maxWidth: "100%",
                objectFit: "contain",
                boxShadow: props.raiseCard
                  ? "1px 4px 8px 0 rgba(0,0,0,0.5)"
                  : undefined,
                borderRadius: props.raiseCard ? 8 : undefined,
              }}
            />
          </Box>
          <>{!isMobileLayout && <Space units={10} />}</>
        </Stack>
      </Card>
      {showModal && (
        <>
          <Dialog
            size="small"
            variant="left"
            title={props.dialogTitle}
            onDismiss={() => {
              setShowModal(false);
            }}
            actions={[]}
            children={props.dialogContent}
          />
          {isBranchExperienceTouchScreen && <VirtualCardsDialogChild />}
        </>
      )}
    </Box>
  );
};
