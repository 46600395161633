import { useEffect, useState } from "react";
import {
  constant,
  constNull,
  constUndefined,
  constVoid,
  pipe,
} from "fp-ts/function";
import { useCommand, useQuery } from "../useAPI";
import * as uploadDocumentsApi from "./api";
import * as remoteData from "../RemoteData";
import { option, boolean, either } from "fp-ts";
import {
  Body,
  Box,
  Button,
  Loader,
  Stack,
  useIsTouchScreen,
} from "design-system";
import { useFormatMessage } from "../intl";
import { srcFromBase64 } from "./utils";
import { Option } from "fp-ts/Option";
import {
  DocumentPreviewDialog,
  DocumentPreview,
} from "./DocumentPreviewDialog";
import * as classes from "./UploadSummary.treat";
import {
  CoApplicantInput,
  CompressedFileContent,
  DocumentIdentificationFlow,
  isSingleDocumentPurpose,
} from "../globalDomain";
import { DocumentReviewDialogChild } from "../Common/Dialogs/DocumentReviewDialog/DocumentReviewDialogChild";
import * as idUploadApi from "../IdUpload/api";
import { NonEmptyString } from "io-ts-types/lib/NonEmptyString";
import { Country } from "./domain";
import { ForeignDocumentType } from "../UKontoSecondPart/api";
import * as uKontoAPI from "../UKontoSecondPart/api";

type Props = {
  documentIdentificationFlow: DocumentIdentificationFlow;
  onRemove: Option<() => unknown>;
  country?: Option<Country>;
  foreignDocumentType?: Option<ForeignDocumentType>;
  saveCurrentSelection?: boolean;
} & CoApplicantInput;

type RetrievedDocumentProps = {
  documents: Array<uploadDocumentsApi.RetrievedDocument>;
  onOpenPreview: (value: DocumentPreview) => unknown;
};
function RetrievedDocument(props: RetrievedDocumentProps) {
  return (
    <Box column grow shrink>
      <Stack units={5} shrink>
        {props.documents.map(
          ({ fileName, fileContent, documentType, documentPurpose }, index) => (
            <Box shrink basis="50%" key={fileName}>
              <img
                className={classes.image}
                width="100%"
                src={pipe(
                  srcFromBase64(fileContent, fileName),
                  option.getOrElse(constant(""))
                )}
                alt={documentType}
                data-test-id={`${documentType}_${documentPurpose}_${index + 1}`}
                onClick={() =>
                  props.onOpenPreview({
                    fileName,
                    fileContent,
                  })
                }
              />
            </Box>
          )
        )}
      </Stack>
    </Box>
  );
}

type RetrievedDocumentsProps = Props & {
  primary: Array<uploadDocumentsApi.RetrievedDocument>;
  secondary: Array<uploadDocumentsApi.RetrievedDocument>;
};
function RetrievedDocuments(props: RetrievedDocumentsProps) {
  const formatMessage = useFormatMessage();
  const isBranchExperienceTouchScreen = useIsTouchScreen();

  const [previewOpened, setPreviewOpened] = useState<Option<DocumentPreview>>(
    option.none
  );

  const selfieLivenessCheck =
    props.documentIdentificationFlow === "PrimaryAndLivenessCheck";

  const saveCountryDocSelection = useCommand(uKontoAPI.saveCountryDocSelection);

  useEffect(() => {
    if (
      props.country &&
      props.foreignDocumentType &&
      props.saveCurrentSelection
    ) {
      saveCountryDocSelection({
        country: props.country,
        foreignDocumentType: props.foreignDocumentType,
      })();
    }
  }, [props.country, props.foreignDocumentType]);

  return (
    <Box column grow shrink>
      <Stack column units={10}>
        {pipe(
          props.onRemove,
          option.fold(constNull, onRemove => (
            <Box>
              <Button
                variant="text"
                size="default"
                action={onRemove}
                label={formatMessage("Identification.UploadDocuments.remove")}
              />
            </Box>
          ))
        )}
        {pipe(
          !!props.primary.length,
          boolean.fold(constUndefined, () => (
            <Stack column units={5}>
              <Body size="medium" weight="medium">
                {formatMessage(
                  "ClientProfile.clientData.IDDocument.primaryDocumentTitle"
                )}
              </Body>
              <RetrievedDocument
                documents={props.primary}
                onOpenPreview={image => setPreviewOpened(option.some(image))}
              />
            </Stack>
          ))
        )}
        {pipe(
          !!props.secondary.length,
          boolean.fold(constUndefined, () => (
            <Stack column units={5}>
              <Body size="medium" weight="medium">
                {formatMessage(
                  "ClientProfile.clientData.IDDocument.secondaryDocumentTitle"
                )}
              </Body>
              <RetrievedDocument
                documents={props.secondary}
                onOpenPreview={image => setPreviewOpened(option.some(image))}
              />
            </Stack>
          ))
        )}
        {selfieLivenessCheck && (
          <SelfieBlock
            onOpenPreview={image => setPreviewOpened(option.some(image))}
          />
        )}
      </Stack>
      {pipe(
        previewOpened,
        option.map(previewSlot => (
          <>
            <DocumentPreviewDialog
              document={previewSlot}
              onDismiss={() => setPreviewOpened(option.none)}
            />
            {isBranchExperienceTouchScreen && <DocumentReviewDialogChild />}
          </>
        )),
        option.toUndefined
      )}
    </Box>
  );
}

function SelfieBlock({
  onOpenPreview,
}: {
  onOpenPreview: (value: DocumentPreview) => unknown;
}) {
  const [fraudCheckSelfie] = useQuery(idUploadApi.fraudCheckSelfie);
  const formatMessage = useFormatMessage();
  return pipe(
    fraudCheckSelfie,
    remoteData.fold(
      () => <Loader />,
      constNull,
      ({ fileContent }) =>
        pipe(
          fileContent,
          option.fold(constNull, fileContent => (
            <Stack column units={5}>
              <Body size="medium" weight="medium">
                {formatMessage("Identification.selfieCheck")}
              </Body>
              <RetrievedSelfie
                onOpenPreview={onOpenPreview}
                fileContent={fileContent}
              />
            </Stack>
          ))
        )
    )
  );
}

function RetrievedSelfie(props: {
  onOpenPreview: (value: DocumentPreview) => unknown;
  fileContent: CompressedFileContent;
}) {
  return (
    <Box grow shrink>
      <Box shrink basis="50%">
        <img
          className={classes.image}
          alt="selfie"
          width="100%"
          src={pipe(
            srcFromBase64(props.fileContent),
            option.getOrElse(constant(""))
          )}
          onClick={() =>
            pipe(
              NonEmptyString.decode("selfie"),
              either.fold(constVoid, fileName =>
                props.onOpenPreview({
                  fileName: fileName,
                  fileContent: props.fileContent,
                })
              )
            )
          }
        />
      </Box>
    </Box>
  );
}

export function UploadSummary(props: Props) {
  const [retrieveDocuments] = useQuery(
    uploadDocumentsApi.retrieveUploadedDocument,
    {
      idType: pipe(
        props.documentIdentificationFlow,
        option.fromPredicate(isSingleDocumentPurpose)
      ),
      slot: option.none,
      coApplicant: props.coApplicant,
    }
  );

  return pipe(
    retrieveDocuments,
    remoteData.fold(
      () => <Loader />,
      constNull,
      ({ primary, secondary }) => (
        <RetrievedDocuments
          {...props}
          primary={primary}
          secondary={secondary}
        />
      )
    )
  );
}
