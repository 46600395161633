import { option, taskEither } from "fp-ts";
import { flow, identity, pipe } from "fp-ts/function";
import { sequenceS } from "fp-ts/Apply";
import { Option } from "fp-ts/Option";
import { TaskEither } from "fp-ts/TaskEither";
import { NonEmptyString } from "io-ts-types/lib/NonEmptyString";
import { AllSupportedCitizenships, PersonalData } from "../IdUpload/domain";
import * as clientDataApi from "../IdUpload/ClientData/api";
import { ApplicantIndex } from "../MortgageDashboard/domain";
import { ReaderTaskEither } from "fp-ts/ReaderTaskEither";
import { ExtractedDataResult } from "./types";

export type ClientExistencyCheckDataPartial = {
  name: NonEmptyString;
  surname: NonEmptyString;
  dateOfBirth: Date;
  birthNumber: Option<NonEmptyString>;
  citizenship: AllSupportedCitizenships;
} & ApplicantIndex;

export type ClientExistencyCheckData = {
  name: NonEmptyString;
  surname: NonEmptyString;
  dateOfBirth: Date;
  birthNumber: NonEmptyString;
  citizenship: AllSupportedCitizenships;
} & ApplicantIndex;

export type ExistencyCheckResult = {
  clientExists: boolean;
  duplicateContacts: boolean;
  hasBlockingNotes: boolean;
  clientExistencyCheckData: ClientExistencyCheckData;
  multipleUsers: boolean;
  personalNumberMatch: boolean;
  userID: Option<string>;
  clientNumber: Option<string>;
};

export const personalNumberFromBirthNumber = flow(
  (birthNumber: NonEmptyString) => birthNumber.replace(/\//g, ""),
  NonEmptyString.decode,
  option.fromEither
);

export function convertToCheckDataBn(
  checkData: Option<ClientExistencyCheckDataPartial>,
  birthnumber?: NonEmptyString
): Option<ClientExistencyCheckData> {
  const result = pipe(
    checkData,
    option.fold(
      () => option.none,
      data =>
        pipe(
          data.birthNumber,
          option.fold(
            () =>
              birthnumber
                ? option.some({
                    ...data,
                    birthNumber: birthnumber,
                  })
                : option.none,
            bn =>
              option.some({
                ...data,
                birthNumber: birthnumber ? birthnumber : bn,
              })
          )
        )
    )
  );
  if (option.isNone(result)) {
    console.trace(">>> no BN....");
  }
  return result;
}

export function clientExistencyCheckDataFromClientData( //partial, BN not mandatory
  clientData: ExtractedDataResult & ApplicantIndex
): Option<ClientExistencyCheckDataPartial> {
  return pipe(
    {
      name: clientData.personalData.name,
      surname: clientData.personalData.surname,
      dateOfBirth: clientData.personalData.dateOfBirth,
      // birthNumber: clientData.personalData.birthNumber,
      citizenship: pipe(
        clientData.personalData.citizenship,
        option.filter((x): x is AllSupportedCitizenships => x !== "OTHER")
      ),
    },
    sequenceS(option.option),
    option.map(data => ({
      ...data,
      birthNumber: clientData.personalData.birthNumber,
      applicantIndex: clientData.applicantIndex,
    }))
  );
}

export function clientExistencyCheckDataFromClientDataAuth(
  clientData: ExtractedDataResult & ApplicantIndex
): Option<ClientExistencyCheckData> {
  return pipe(
    {
      name: clientData.personalData.name,
      surname: clientData.personalData.surname,
      dateOfBirth: clientData.personalData.dateOfBirth,
      birthNumber: clientData.personalData.birthNumber,
      citizenship: pipe(
        clientData.personalData.citizenship,
        option.filter((x): x is AllSupportedCitizenships => x !== "OTHER")
      ),
    },
    sequenceS(option.option),
    option.map(data => ({
      ...data,
      birthNumber: data.birthNumber,
      applicantIndex: clientData.applicantIndex,
    }))
  );
}

export function clientExistencyCheckDataFromPersonalData(
  personalData: PersonalData & ApplicantIndex
): ClientExistencyCheckDataPartial {
  return {
    name: personalData.name,
    surname: personalData.surname,
    birthNumber: personalData.birthNumber,
    dateOfBirth: personalData.dateOfBirth,
    citizenship: personalData.citizenship,
    applicantIndex: personalData.applicantIndex,
  };
}

export function inputFromClientExistencyCheckData(
  data: ClientExistencyCheckData & ApplicantIndex
): Option<clientDataApi.CheckExistingClientInput> {
  return pipe(
    data.birthNumber,
    personalNumberFromBirthNumber,
    option.map(personalNumber => ({
      dateOfBirth: data.dateOfBirth,
      lastAndFirstName: `${data.surname} ${data.name}` as NonEmptyString,
      personalNumber: personalNumber,
      citizenship: data.citizenship,
      applicantIndex: data.applicantIndex,
    }))
  );
}

export function clientExistencyCheck<E = unknown>(
  clientExistencyCommand: ClientExistencyCommand<E>,
  defaultError: () => E
) {
  return (
    data: ClientExistencyCheckData
  ): TaskEither<E, ExistencyCheckResult> =>
    pipe(
      data,
      inputFromClientExistencyCheckData,
      taskEither.fromOption(defaultError),
      taskEither.chain(clientExistencyCommand),
      taskEither.bimap(
        identity,
        ({
          existingClient,
          duplicateContacts,
          hasBlockingNotes,
          multipleUsers,
          personalNumberMatch,
          userID,
          clientNumber,
        }) => ({
          clientExists: existingClient,
          duplicateContacts,
          hasBlockingNotes,
          clientExistencyCheckData: data,
          multipleUsers,
          personalNumberMatch,
          userID,
          clientNumber,
        })
      )
    );
}

export type ClientExistencyCommand<E = unknown> = ReaderTaskEither<
  clientDataApi.CheckExistingClientInput,
  E,
  clientDataApi.CheckExistingClientOutput
>;

export function useClientExistencyCheck<E = unknown>(
  clientExistencyCommand: ClientExistencyCommand<E>,
  defaultError: () => E
) {
  return (
    data: ClientExistencyCheckData
  ): TaskEither<E, ExistencyCheckResult> =>
    clientExistencyCheck(clientExistencyCommand, defaultError)(data);
}
