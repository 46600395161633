import { useState } from "react";
import { option, task, taskEither, either } from "fp-ts";
import { Option } from "fp-ts/Option";
import { useFormatMessage } from "../../intl";
import { constant, constVoid, pipe } from "fp-ts/function";
import {
  DocumentType,
  DocumentPage,
  ScannedDocument,
  DocumentSlot,
  DocumentToUploadDetail,
} from "../domain";
import {
  Body,
  Box,
  ErrorBanner,
  Space,
  AlertDialog,
  Dialog,
  LocalizedString,
} from "design-system";

import { NonEmptyArray } from "fp-ts/NonEmptyArray";
import {
  CoApplicantInput,
  genericError,
  DocumentPurpose,
  foldDocumentIdentificationFlow,
  DocumentIdentificationFlow,
} from "../../globalDomain";
import * as uploadDocumentsApi from "../api";
import { srcFromBase64 } from "../utils";
import { NonEmptyString } from "io-ts-types/lib/NonEmptyString";
import { TaskEither } from "fp-ts/TaskEither";
import * as classes from "./ScannerIdUpload.treat";
import { ScanDocument } from "../ScanDocument";
import { UploadDocumentCommand } from "../api";
import { usePortalStatusContext } from "../../PortalStatusContext";

type Props = {
  _mockScanner: boolean;
  onContinue: () => unknown;
  onDismiss: () => unknown;
  documentIdentificationFlow: DocumentIdentificationFlow;
  uploadCommand: UploadDocumentCommand;
  documentDetails: Option<DocumentToUploadDetail>;
} & CoApplicantInput;

export type ScannerUploadError = uploadDocumentsApi.UploadDocumentError;

type ScanDocumentState = {
  id: "ScanDocument";
  error: Option<ScannerUploadError>;
} & (
  | {
      documentPurpose: DocumentPurpose;
      slot: "First";
      documentType?: never;
      documentPage?: never;
    }
  | {
      documentPurpose: DocumentPurpose;
      slot: "Second";
      documentType: DocumentType;
      documentPage: DocumentPage;
    }
);
type ConfirmScanState = {
  id: "ConfirmScan";
  documentPurpose: DocumentPurpose;
  slot: "First" | "Second";
  documentType: DocumentType;
  documentPage: DocumentPage;
  documentSrc: NonEmptyString;
  singleSidedID: boolean;
};
type ScannerState = (ScanDocumentState | ConfirmScanState) & {
  isCancelling: boolean;
};

type UploadedFileResult = {
  fileSrc: NonEmptyString;
  documentType: DocumentType;
  documentPage: DocumentPage;
  singleSidedID: boolean;
};

function foldScannerState<T>(
  state: ScannerState,
  match: {
    whenScanDocument: (state: ScanDocumentState) => T;
    whenConfirmScan: (state: ConfirmScanState) => T;
  }
) {
  switch (state.id) {
    case "ScanDocument":
      return match.whenScanDocument(state);
    case "ConfirmScan":
      return match.whenConfirmScan(state);
  }
}

export function ScannerIdUpload(props: Props) {
  const formatMessage = useFormatMessage();
  const { portalBlocked } = usePortalStatusContext();

  const [scannerState, setScannerState] = useState<ScannerState>({
    id: "ScanDocument",
    documentPurpose: foldDocumentIdentificationFlow(
      props.documentIdentificationFlow,
      {
        Primary: constant("Primary"),
        Secondary: constant("Secondary"),
        PrimaryAndSecondary: constant("Primary"),
        PrimaryAndLivenessCheck: constant("Primary"),
      }
    ),
    slot: "First",
    error: option.none,
    isCancelling: false,
  });

  const formatScannerError = (
    error: ScannerUploadError
  ): NonEmptyArray<LocalizedString> => {
    switch (error.id) {
      case "GenericError":
        return [
          formatMessage("Identification.UploadDocuments.scannerError.generic"),
        ];
      case "LowQualityError":
        return [
          formatMessage(
            "Identification.UploadDocuments.scannerError.lowQuality"
          ),
          formatMessage(
            "Identification.UploadDocuments.scannerError.scanAgain"
          ),
        ];
      case "InvalidDocument":
        return [
          formatMessage(
            "Identification.UploadDocuments.scannerError.invalidDocument"
          ),
          formatMessage(
            "Identification.UploadDocuments.scannerError.scanAgain"
          ),
        ];
      case "InvalidDocumentCountry":
        return [
          formatMessage(
            "Identification.UploadDocuments.scannerError.invalidDocumentCountry"
          ),
          formatMessage(
            "Identification.UploadDocuments.scannerError.scanAgain"
          ),
        ];
      case "InvalidDocumentRemoteIdentification":
        return [
          formatMessage(
            "Identification.UploadDocuments.scannerError.invalidDocumentRemoteIdentification"
          ),
          formatMessage(
            "Identification.UploadDocuments.scannerError.scanAgain"
          ),
        ];
      case "InvalidPage":
        return [
          formatMessage(
            "Identification.UploadDocuments.scannerError.invalidPage"
          ),
          formatMessage(
            "Identification.UploadDocuments.scannerError.scanAgain"
          ),
        ];
      case "WrongDocumentType":
        return [
          formatMessage(
            "Identification.UploadDocuments.scannerError.wrongDocumentType"
          ),
          formatMessage(
            "Identification.UploadDocuments.scannerError.scanAgain"
          ),
        ];
    }
  };

  const formatDocumentPage = (page: DocumentPage): LocalizedString => {
    switch (page) {
      case "Front":
        return formatMessage(
          "Identification.UploadDocuments.documentPage.front"
        );
      case "Back":
        return formatMessage(
          "Identification.UploadDocuments.documentPage.back"
        );
    }
  };

  const formatDocumentType = (type: DocumentType): LocalizedString => {
    switch (type) {
      case "IDCard":
        return formatMessage(
          "Identification.UploadDocuments.documentType.idCard"
        );
      case "Passport":
        return formatMessage(
          "Identification.UploadDocuments.documentType.passport"
        );
      case "DrivingLicense":
        return formatMessage(
          "Identification.UploadDocuments.documentType.drivingLicense"
        );
      case "LongTermResidencePermit":
        return formatMessage(
          "Identification.UploadDocuments.documentType.longTermResidecePermit"
        );
      case "PermanentResidencePermit":
        return formatMessage(
          "Identification.UploadDocuments.documentType.permanentResidencePermit"
        );
      case "TemporaryResidencePermit":
        return formatMessage(
          "Identification.UploadDocuments.documentType.temporaryResidencePermit"
        );
    }
  };

  const uploadDocument = (
    scannedDocument: ScannedDocument,
    documentPurpose: DocumentPurpose,
    slot: DocumentSlot
  ): TaskEither<ScannerUploadError, UploadedFileResult> =>
    pipe(
      props.uploadCommand({
        fileContent: scannedDocument.base64,
        slot: slot,
        coApplicant: props.coApplicant,
        idType: documentPurpose,
        countryCode: pipe(
          props.documentDetails,
          option.fold(
            () => option.none,
            val => option.some(val.country.countryCode)
          )
        ),
        documentType: pipe(
          props.documentDetails,
          option.fold(
            () => option.none,
            val => val.type
          )
        ),
      }),
      taskEither.chain(uploadResponse =>
        pipe(
          srcFromBase64(scannedDocument.base64, scannedDocument.fileName),
          taskEither.fromOption<ScannerUploadError>(constant(genericError)),
          taskEither.map(fileSrc => ({ ...uploadResponse, fileSrc }))
        )
      )
    );

  const onDocumentAcquired = (
    scannedDocument: ScannedDocument
  ): TaskEither<unknown, unknown> => {
    if (scannerState.id === "ScanDocument") {
      return pipe(
        uploadDocument(
          scannedDocument,
          scannerState.documentPurpose,
          scannerState.slot
        ),
        taskEither.chain(uploadResult =>
          taskEither.fromIO(() => onDocumentUploaded(uploadResult))
        ),
        taskEither.orElse(err =>
          pipe(
            task.fromIO(() => setUploadError(err)),
            task.map(() => either.left(err))
          )
        )
      );
    } else {
      return taskEither.fromIO(constVoid);
    }
  };

  const setUploadError = (error: ScannerUploadError) => {
    if (scannerState.id === "ScanDocument") {
      setScannerState({
        ...scannerState,
        error: option.some(error),
      });
    }
  };

  const onDocumentUploaded = ({
    documentType,
    documentPage,
    fileSrc,
    singleSidedID,
  }: UploadedFileResult) => {
    return setScannerState(state => ({
      id: "ConfirmScan",
      documentPurpose: state.documentPurpose,
      slot: scannerState.slot,
      documentType,
      documentPage,
      documentSrc: fileSrc,
      isCancelling: state.isCancelling,
      singleSidedID,
    }));
  };

  const scanAgain = (state: ConfirmScanState) => {
    setScannerState(
      state.slot === "Second"
        ? {
            id: "ScanDocument",
            documentPurpose: state.documentPurpose,
            slot: "Second",
            documentType: state.documentType,
            documentPage: state.documentPage,
            error: option.none,
            isCancelling: false,
          }
        : {
            id: "ScanDocument",
            documentPurpose: state.documentPurpose,
            slot: "First",
            error: option.none,
            isCancelling: false,
          }
    );
  };

  const confirmScan = (state: ConfirmScanState) => {
    const hasTwoSides = state.documentType === "IDCard" && !state.singleSidedID;
    if (state.slot === "First" && hasTwoSides) {
      setScannerState({
        id: "ScanDocument",
        documentPurpose: state.documentPurpose,
        slot: "Second",
        documentType: state.documentType,
        documentPage: state.documentPage === "Front" ? "Back" : "Front",
        error: option.none,
        isCancelling: false,
      });
    } else if (
      state.documentPurpose === "Primary" &&
      props.documentIdentificationFlow === "PrimaryAndSecondary"
    ) {
      setScannerState({
        id: "ScanDocument",
        documentPurpose: "Secondary",
        slot: "First",
        error: option.none,
        isCancelling: false,
      });
    } else {
      props.onContinue();
    }
  };

  type DialogActions = React.ComponentProps<typeof Dialog>["actions"];

  const dialogActions: DialogActions = foldScannerState(scannerState, {
    whenScanDocument: constant<DialogActions>([]),
    whenConfirmScan: state =>
      !portalBlocked
        ? [
            {
              label: formatMessage(
                "Identification.UploadDocuments.scanner.scanAgain"
              ),
              variant: "secondary",
              action: () => scanAgain(state),
              dataTestId: "scan_again_button",
            },
            {
              label: formatMessage(
                "Identification.UploadDocuments.scanner.proceed"
              ),
              variant: "primary",
              action: () => confirmScan(state),
              dataTestId: "proceed_button",
            },
          ]
        : [],
  });

  const modalTitle = foldScannerState(scannerState, {
    whenScanDocument: state =>
      state.slot === "First"
        ? formatMessage(
            "Identification.UploadDocuments.scanner.scanDocumentTitle"
          )
        : formatMessage(
            "Identification.UploadDocuments.scanner.scanSecondPageTitle",
            {
              documentType: formatDocumentType(state.documentType),
              page: formatDocumentPage(state.documentPage),
            }
          ),
    whenConfirmScan: state =>
      formatMessage("Identification.UploadDocuments.scanner.confirmScanTitle", {
        page: formatDocumentPage(state.documentPage),
        document: formatDocumentType(state.documentType),
      }),
  });

  const modalContent = foldScannerState(scannerState, {
    whenScanDocument: state => (
      <>
        <Body size="medium" weight="regular">
          {formatMessage(
            "Identification.UploadDocuments.scanner.scanDocumentDescription"
          )}
        </Body>
        <Space units={10} />
        <Box hAlignContent="center">
          <ScanDocument
            _mockScanner={props._mockScanner}
            onDocumentAcquired={onDocumentAcquired}
            onFailure={() => setUploadError(genericError)}
            documentPurpose={option.some(state.documentPurpose)}
            documentDetails={props.documentDetails}
          />
        </Box>
        {pipe(
          state.error,
          option.map(e => (
            <Box column>
              <Space units={10} />
              <ErrorBanner>{formatScannerError(e)}</ErrorBanner>
            </Box>
          )),
          option.toNullable
        )}
      </>
    ),
    whenConfirmScan: ({ documentSrc, documentType, documentPage }) => {
      return (
        <>
          <Body size="medium" weight="regular" align="center">
            {formatMessage(
              "Identification.UploadDocuments.scanner.confirmScanDescription",
              {
                document: formatDocumentType(documentType),
                page: formatDocumentPage(documentPage),
              }
            )}
          </Body>
          <Space units={10} />
          <Box grow shrink hAlignContent="center">
            <img
              src={documentSrc}
              alt={formatDocumentType(documentType)}
              className={classes.image}
            />
          </Box>
        </>
      );
    },
  });

  return scannerState.isCancelling ? (
    <AlertDialog
      type="disruptive"
      title={formatMessage(
        "Identification.UploadDocuments.scanner.cancellingTitle"
      )}
      message={formatMessage(
        "Identification.UploadDocuments.scanner.cancellingDescription"
      )}
      confirmLabel={formatMessage(
        "Identification.UploadDocuments.scanner.confirmCancel"
      )}
      onConfirm={props.onDismiss}
      cancelLabel={formatMessage(
        "Identification.UploadDocuments.scanner.dontCancel"
      )}
      onCancel={() =>
        setScannerState({
          ...scannerState,
          isCancelling: false,
          error: option.none,
        } as ScannerState)
      }
      onDismiss={() =>
        setScannerState({ ...scannerState, isCancelling: false })
      }
    />
  ) : (
    <Dialog
      variant="left"
      size="large"
      title={modalTitle}
      actions={dialogActions}
      onDismiss={option.some(() =>
        setScannerState({ ...scannerState, isCancelling: true })
      )}
    >
      {modalContent}
    </Dialog>
  );
}
