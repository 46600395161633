import * as t from "io-ts";
import { failure } from "io-ts/lib/PathReporter";
import { NonEmptyString } from "io-ts-types/lib/NonEmptyString";
import { either } from "fp-ts";
import { pipe } from "fp-ts/function";
import { BuildConfig } from "./ConfigBlocks";
import { optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import { PositiveInteger } from "design-system";
import { NumberFromString } from "io-ts-types/lib/NumberFromString";

const BuildConfigCodec = t.type(
  {
    REACT_APP_API_ENDPOINT: optionFromNullable(NonEmptyString),
    REACT_APP_API_PORT: optionFromNullable(
      NumberFromString.pipe(PositiveInteger)
    ),
  },
  "BuildConfig"
);

export const buildConfig: BuildConfig = pipe(
  BuildConfigCodec.decode(process.env),
  either.fold(
    errors => {
      throw new Error(failure(errors).join("\n"));
    },
    (env): BuildConfig => ({
      apiEndpoint: env.REACT_APP_API_ENDPOINT,
      apiPort: env.REACT_APP_API_PORT,
    })
  )
);
