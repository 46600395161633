import {
  Channel,
  CoApplicantInput,
  CompressedFileContent,
  DocumentIdentificationFlow,
  DocumentPurpose,
  eqCoApplicantInput,
  eqWithCoApplicant,
  GenericError,
  optionFromUndefined,
  UploadDocumentFlowType,
  VerifyLinkError,
  withCoApplicant,
} from "../globalDomain";
import * as t from "io-ts";
import { NonEmptyString } from "io-ts-types/lib/NonEmptyString";
import { eq, option } from "fp-ts";
import { Eq } from "fp-ts/Eq";
import { constFalse } from "fp-ts/function";
import {
  Country,
  DocumentPage,
  DocumentSlot,
  DocumentType,
  LinkPurpose,
  MobileRecipientNumber,
  MobileRecipientType,
  MobileStatus,
} from "./domain";
import { ClientDataOCR } from "../IdUpload/domain";
import { apiCall } from "../APICall";
import { NonNegativeInteger } from "design-system";
import { UUID } from "io-ts-types/lib/UUID";
import {
  eqWithApplicantIndex,
  withApplicantIndex,
} from "../MortgageDashboard/domain";
import { ReaderTaskEither } from "fp-ts/ReaderTaskEither";
import { withFallback } from "io-ts-types/lib/withFallback";
import { optionFromNullable } from "io-ts-types/lib/optionFromNullable";

const BiometricConsentInput = withApplicantIndex(
  t.type(
    {
      consent: t.boolean,
    },
    "BiometricConsentInput"
  )
);

export interface BiometricConsentInput
  extends t.TypeOf<typeof BiometricConsentInput> {}

const eqBiometricConsentInput: Eq<BiometricConsentInput> = eqWithApplicantIndex(
  eq.getStructEq({
    consent: eq.eqBoolean,
  })
);

export const sendBiometricConsent = apiCall({
  inputCodec: BiometricConsentInput,
  inputEq: eqBiometricConsentInput,
  outputCodec: t.unknown,
  path: ["clients", "identification", "biometricConsent"],
});

export const UploadDocumentInput = withCoApplicant(
  t.type({
    fileContent: CompressedFileContent,
    slot: DocumentSlot,
    idType: DocumentPurpose,
    countryCode: optionFromUndefined(NonEmptyString),
    documentType: optionFromUndefined(DocumentType),
  })
);
export type UploadDocumentInput = t.TypeOf<typeof UploadDocumentInput>;

export const eqUploadDocumentInput: Eq<UploadDocumentInput> = eqWithCoApplicant(
  eq.getStructEq({
    fileContent: eq.eqString,
    slot: eq.eqStrict,
    idType: eq.eqString,
  })
);

export const UploadDocumentOutput = t.type({
  documentType: DocumentType,
  documentPage: DocumentPage,
  singleSidedID: t.boolean,
});

export type UploadDocumentOutput = t.TypeOf<typeof UploadDocumentOutput>;

export const UploadDocumentError = t.union([
  GenericError,
  t.type({ id: t.literal("LowQualityError") }),
  t.type({ id: t.literal("InvalidDocumentCountry") }),
  t.type({ id: t.literal("InvalidDocument") }),
  t.type({ id: t.literal("InvalidDocumentRemoteIdentification") }),
  t.type({ id: t.literal("InvalidPage") }),
  t.type({ id: t.literal("WrongDocumentType") }),
]);

export type UploadDocumentError = t.TypeOf<typeof UploadDocumentError>;

export type UploadDocumentCommand = ReaderTaskEither<
  UploadDocumentInput,
  UploadDocumentError | GenericError,
  UploadDocumentOutput
>;

export const uploadDocument = apiCall({
  inputEq: eqUploadDocumentInput,
  path: ["clients", "identification", "upload"],
  inputCodec: UploadDocumentInput,
  outputCodec: UploadDocumentOutput,
  errorCodec: UploadDocumentError,
});

const MobileUploadDocumentInput = withCoApplicant(
  t.type({
    documentCode: NonNegativeInteger,
    pageCode: NonNegativeInteger,
    fileContent: CompressedFileContent,
    slot: DocumentSlot,
    idType: DocumentPurpose,
    async: t.boolean,
    countryCode: optionFromUndefined(NonEmptyString),
  })
);

type MobileUploadDocumentInput = t.TypeOf<typeof MobileUploadDocumentInput>;

const eqMobileUploadDocumentInput: Eq<MobileUploadDocumentInput> = eqWithCoApplicant(
  eq.getStructEq({
    documentCode: eq.eqNumber,
    pageCode: eq.eqNumber,
    fileContent: eq.eqString,
    slot: eq.eqStrict,
    idType: eq.eqString,
  })
);

export const mobileUploadDocument = apiCall({
  inputEq: eqMobileUploadDocumentInput,
  path: ["clients", "identification", "mobileUpload"],
  inputCodec: MobileUploadDocumentInput,
  outputCodec: UploadDocumentOutput,
});

export const mobileUploadDocument2 = apiCall({
  inputEq: eqMobileUploadDocumentInput,
  path: ["clients", "identification", "mobileUpload"],
  inputCodec: MobileUploadDocumentInput,
  outputCodec: t.unknown,
});

export const DocumentRetrieveContentInput = withCoApplicant(
  t.type(
    {
      idType: optionFromUndefined(DocumentPurpose),
      slot: optionFromUndefined(DocumentSlot),
    },
    "DocumentRetrieveContentInput"
  )
);

interface DocumentRetrieveContentInput
  extends t.TypeOf<typeof DocumentRetrieveContentInput> {}

export const eqDocumentRetrieveContentInput = eqWithCoApplicant(
  eq.getStructEq({
    slot: option.getEq(eq.eqString),
    idType: option.getEq(eq.eqString),
  })
);

const RetrievedDocument = t.type({
  fileName: NonEmptyString,
  compressed: t.boolean,
  documentPurpose: DocumentPurpose,
  documentType: DocumentType,
  subType: DocumentPage,
  fileContent: CompressedFileContent,
});

export type RetrievedDocument = t.TypeOf<typeof RetrievedDocument>;

const DocumentRetrieveContentOutput = t.type(
  {
    primary: t.array(RetrievedDocument),
    secondary: t.array(RetrievedDocument),
  },
  "DocumentContentOutput"
);

interface DocumentRetrieveContentOutput
  extends t.TypeOf<typeof DocumentRetrieveContentOutput> {}

export const retrieveUploadedDocument = apiCall({
  inputEq: eqDocumentRetrieveContentInput,
  path: ["clients", "identification", "retrieveUploadedDocument"],
  inputCodec: DocumentRetrieveContentInput,
  outputCodec: DocumentRetrieveContentOutput,
});

// UploadId - mobile flow
const MobileRecipientsInput = withCoApplicant(
  t.type({
    linkPurpose: LinkPurpose,
  })
);
export type MobileRecipientsInput = t.TypeOf<typeof MobileRecipientsInput>;
const eqMobileRecipientsInput: Eq<MobileRecipientsInput> = eqCoApplicantInput;
const MobileRecipientsOutput = t.record(
  MobileRecipientType,
  MobileRecipientNumber
);
export type MobileRecipientsOutput = t.TypeOf<typeof MobileRecipientsOutput>;
export const mobileRecipients = apiCall({
  path: ["clients", "identification", "mobileUpload", "recipients"],
  inputCodec: MobileRecipientsInput,
  inputEq: eqMobileRecipientsInput,
  outputCodec: MobileRecipientsOutput,
});

const SendMobileLinkInput = withCoApplicant(
  t.type({
    recipient: MobileRecipientType,
    linkPurpose: LinkPurpose,
    idType: DocumentIdentificationFlow,
    parameters: t.type({
      countryCode: optionFromUndefined(NonEmptyString),
      documentType: optionFromUndefined(DocumentType),
    }),
  })
);
const SendMobileLinkInputPOI = withCoApplicant(
  t.type({
    recipient: MobileRecipientType,
    linkPurpose: LinkPurpose,
    idType: DocumentIdentificationFlow,
    parameters: t.type({
      docTypeId: optionFromNullable(NonEmptyString),
      applicationElementID: optionFromNullable(NonEmptyString),
    }),
  })
);

const eqSendMobileLinkInput: Eq<any> = eq.fromEquals(constFalse);
const SendMobileLinkOutput = t.type({ attemptsLeft: NonNegativeInteger });
const SendMobileLinkError = t.union([
  t.type({ id: t.literal("InvalidFlowId") }),
  t.type({ id: t.literal("MaxLinksAttemptsReached") }),
]);
export type SendMobileLinkError = t.TypeOf<typeof SendMobileLinkError>;
export const sendMobileLink = apiCall({
  path: ["clients", "identification", "mobileUpload", "link"],
  inputCodec: SendMobileLinkInput,
  inputEq: eqSendMobileLinkInput,
  outputCodec: SendMobileLinkOutput,
  errorCodec: SendMobileLinkError,
});
export const sendMobileLinkPOI = apiCall({
  path: ["clients", "identification", "mobileUpload", "link"],
  inputCodec: SendMobileLinkInputPOI,
  inputEq: eqSendMobileLinkInput,
  outputCodec: SendMobileLinkOutput,
  errorCodec: SendMobileLinkError,
});

const MobileStatusInput = CoApplicantInput;
type MobileStatusInput = t.TypeOf<typeof MobileStatusInput>;
const eqMobileStatusInput: Eq<MobileStatusInput> = eq.fromEquals(constFalse);
const MobileStatusOutput = t.type({ status: MobileStatus });
export type MobileStatusOutput = t.TypeOf<typeof MobileStatusOutput>;
export const mobileStatus = apiCall({
  path: ["clients", "identification", "mobileUpload", "status"],
  inputCodec: MobileStatusInput,
  inputEq: eqMobileStatusInput,
  outputCodec: MobileStatusOutput,
});

const CancelProcessInput = t.void;
type CancelProcessInput = t.TypeOf<typeof CancelProcessInput>;
const eqCancelProcessInput: Eq<CancelProcessInput> = eq.fromEquals(constFalse);
export const cancelProcess = apiCall({
  path: ["clients", "identification", "mobileUpload", "cancel"],
  inputCodec: CancelProcessInput,
  inputEq: eqCancelProcessInput,
});

export const VerifyLinkInput = t.type({
  id: UUID,
});
export type VerifyLinkInput = t.TypeOf<typeof VerifyLinkInput>;

export const eqVerifyLinkInput = eq.getStructEq({
  id: eq.eqStrict,
});

export const verifyLink = apiCall({
  path: ["clients", "identification", "mobileUpload", "link", "verify"],
  inputCodec: VerifyLinkInput,
  inputEq: eqVerifyLinkInput,
  errorCodec: VerifyLinkError,
});

const StartProcessInput = t.type({
  token: NonEmptyString,
});
type StartProcessInput = t.TypeOf<typeof StartProcessInput>;
const eqStartProcessInput: Eq<StartProcessInput> = eq.fromEquals(constFalse);
const StartProcessError = t.union([
  t.type({ id: t.literal("InvalidFlowId") }),
  t.type({ id: t.literal("InvalidToken") }),
]);
export type StartProcessError = t.TypeOf<typeof StartProcessError>;
const StartProcessOutput = t.type({
  token: NonEmptyString,
});
export const startProcess = apiCall({
  path: ["clients", "identification", "mobileUpload", "start"],
  inputCodec: StartProcessInput,
  inputEq: eqStartProcessInput,
  errorCodec: StartProcessError,
  outputCodec: StartProcessOutput,
});

export const startProofOfIncomeProcess = apiCall({
  path: ["clients", "identification", "mobileUpload", "documents", "start"],
  inputCodec: t.void,
  inputEq: eq.fromEquals(constFalse),
  outputCodec: t.unknown,
});

export type MobileUploadStatusError = t.TypeOf<
  typeof MobileFlowStatusError
>["id"];

const MobileFlowStatusInput = t.void;
export const MobileFlowStatusError = t.union([
  GenericError,
  t.type({ id: t.literal("InvalidDocument") }),
  t.type({ id: t.literal("InvalidDocumentCountry") }),
  t.type({ id: t.literal("InvalidPage") }),
]);
export const mobileFlowStatus = apiCall({
  path: ["clients", "identification", "mobileUpload", "mobileStatus"],
  inputEq: eq.fromEquals(constFalse),
  inputCodec: MobileFlowStatusInput,
  outputCodec: MobileStatusOutput,
  errorCodec: MobileFlowStatusError,
});

export const MobileClientDataOutput = t.type({
  primary: optionFromUndefined(ClientDataOCR),
  secondary: optionFromUndefined(ClientDataOCR),
});
export type MobileClientDataOutput = t.TypeOf<typeof MobileClientDataOutput>;
const MobileClientDataInput = withCoApplicant(
  t.type({
    idType: DocumentIdentificationFlow,
  })
);
export type MobileClientDataInput = t.TypeOf<typeof MobileClientDataInput>;
const eqMobileClientDataInput = eqWithCoApplicant(
  eq.getStructEq({
    idType: eq.eqString,
  })
);
export const mobileClientData = apiCall({
  path: ["clients", "identification", "mobileUpload", "extractData"],
  inputCodec: MobileClientDataInput,
  inputEq: eqMobileClientDataInput,
  outputCodec: MobileClientDataOutput,
});
const ConfirmDataInput = withCoApplicant(
  t.type({
    idType: DocumentIdentificationFlow,
  })
);
export type ConfirmDataInput = t.TypeOf<typeof ConfirmDataInput>;
const eqConfirmDataInput = eqWithCoApplicant(
  eq.getStructEq({
    idType: eq.eqString,
  })
);
export const mobileConfirmData = apiCall({
  path: ["clients", "identification", "mobileUpload", "extractData", "confirm"],
  inputCodec: ConfirmDataInput,
  inputEq: eqConfirmDataInput,
});

const MobileRejectDataInput = withCoApplicant(
  t.type({
    idType: DocumentIdentificationFlow,
  })
);
export type MobileRejectDataInput = t.TypeOf<typeof MobileRejectDataInput>;
const eqMobileRejectDataInput = eqWithCoApplicant(
  eq.getStructEq({
    idType: eq.eqString,
  })
);
const MobileRejectDataOutput = t.type({
  canRetry: t.boolean,
});
export type MobileRejectDataOutput = t.TypeOf<typeof MobileRejectDataOutput>;
export const mobileRejectData = apiCall({
  path: ["clients", "identification", "mobileUpload", "extractData", "reject"],
  inputCodec: MobileRejectDataInput,
  inputEq: eqMobileRejectDataInput,
  outputCodec: MobileRejectDataOutput,
});

const MobileUploadHologramInput = withCoApplicant(
  t.type({
    documentCode: NonNegativeInteger,
    pageCode: NonNegativeInteger,
    fileContent: t.unknown, // TODO(pedrodim): it should be Blob
  })
);
export type MobileUploadHologramInput = t.TypeOf<
  typeof MobileUploadHologramInput
>;

const eqMobileUploadHologramInput: Eq<MobileUploadHologramInput> = eqWithCoApplicant(
  eq.getStructEq({
    documentCode: eq.eqNumber,
    pageCode: eq.eqNumber,
    fileContent: eq.eqStrict,
  })
);

export const mobileUploadHologram = apiCall({
  inputEq: eqMobileUploadHologramInput,
  path: [
    "clients",
    "identification",
    "mobileUpload",
    "uploadHologramVideoForFraudCheck",
  ],
  inputCodec: MobileUploadHologramInput,
  bodyType: "Binary",
});

const MobileUploadSelfieInput = withCoApplicant(
  t.type({
    fileContent: CompressedFileContent,
    signature: t.string,
    async: t.boolean,
  })
);
export type MobileUploadSelfieInput = t.TypeOf<typeof MobileUploadSelfieInput>;

const eqMobileUploadSelfieInput: Eq<MobileUploadSelfieInput> = eqWithCoApplicant(
  eq.getStructEq({
    fileContent: eq.eqString,
    signature: eq.eqString,
  })
);

export const mobileUploadSelfie = apiCall({
  inputEq: eqMobileUploadSelfieInput,
  path: [
    "clients",
    "identification",
    "mobileUpload",
    "uploadSelfiePictureForFraudCheck",
  ],
  inputCodec: MobileUploadSelfieInput,
});

const MobileUploadParametersOutput = withCoApplicant(
  t.type(
    {
      idType: DocumentIdentificationFlow,
      channel: Channel,
      productType: withFallback(
        optionFromUndefined(UploadDocumentFlowType),
        option.none
      ),
      parameters: optionFromNullable(
        t.type({
          docTypeId: optionFromNullable(NonEmptyString),
          applicationElementID: optionFromNullable(NonEmptyString),
          countryCode: optionFromUndefined(NonEmptyString),
          documentType: optionFromUndefined(DocumentType),
        })
      ),
    },
    "MobileUploadParametersOutput"
  )
);
export type MobileUploadParametersOutput = t.TypeOf<
  typeof MobileUploadParametersOutput
>;

export const mobileUploadParameters = apiCall({
  path: ["clients", "identification", "mobileUpload", "parameters"],
  inputCodec: VerifyLinkInput,
  inputEq: eqVerifyLinkInput,
  outputCodec: MobileUploadParametersOutput,
});

export const DocumentIdCodeOutput = t.type({
  primaryIdCodes: t.array(NonEmptyString),
});
export type DocumentIdCodeOutput = t.TypeOf<typeof DocumentIdCodeOutput>;
export const mobileUploadIdCodes = apiCall({
  path: ["clients", "identification", "mobileUpload", "documentIdCodes"],
  inputCodec: t.type({
    iso3country: NonEmptyString,
    documentType: DocumentType,
  }),
  inputEq: eq.getStructEq({
    iso3country: eq.eqString,
  }),
  outputCodec: DocumentIdCodeOutput,
});

export const mobileUploadSelfieVideo = apiCall({
  inputEq: eqWithCoApplicant(eq.getStructEq({ fileContent: eq.eqStrict })),
  path: [
    "clients",
    "identification",
    "mobileUpload",
    "uploadSelfieVideoForFraudCheck",
  ],
  inputCodec: withCoApplicant(t.type({ fileContent: t.unknown })),
  bodyType: "Binary",
});

export const InvestigationStatus = t.union([
  t.literal("Error"),
  t.literal("InProgress"),
  t.literal("Complete"),
]);

export type MobileDocumentError = t.TypeOf<typeof MobileDocumentError>;
export const MobileDocumentError = t.keyof(
  {
    Moire: true,
    DisplayOrPaper: true,
    Expired: true,
    WrongFront: true,
    WrongBack: true,
    WrongBothSides: true,
    DocumentComplete: true,
  },
  "MobileDocumentError"
);

export type MobileInvestigationError = t.TypeOf<
  typeof MobileInvestigationError
>;
export const MobileInvestigationError = t.type(
  {
    errorPrimary: optionFromUndefined(MobileDocumentError),
    errorSecondary: optionFromUndefined(MobileDocumentError),
  },
  "MobileInvestigationError"
);

export const MobileInvestigateStatusOutput = t.type({
  investigationStatus: InvestigationStatus,
  error: optionFromUndefined(MobileInvestigationError),
});
export type MobileInvestigateStatusOutput = t.TypeOf<
  typeof MobileInvestigateStatusOutput
>;
export const mobileInvestigateStatus = apiCall({
  path: ["clients", "identification", "mobileUpload", "investigateStatus"],
  inputCodec: MobileClientDataInput,
  inputEq: eqMobileClientDataInput,
  outputCodec: MobileInvestigateStatusOutput,
});

const CountriesOutput = t.array(Country);
export const allCountries = apiCall({
  path: ["clients", "foreign", "noauth", "supportedCountries"],
  inputCodec: t.void,
  inputEq: eq.fromEquals(constFalse),
  outputCodec: CountriesOutput,
});

const DocumentTypesOutput = t.array(DocumentType);
export const documentTypes = apiCall({
  path: ["clients", "foreign", "noauth", "supportedPrimaryDocumentTypes"],
  inputCodec: t.type({ iso3country: NonEmptyString }),
  inputEq: eq.getStructEq({
    iso3country: eq.eqString,
  }),
  outputCodec: DocumentTypesOutput,
});

export const resetUploadDocument = apiCall({
  inputEq: eq.fromEquals(constFalse),
  path: ["clients", "identification", "resetUpload"],
  inputCodec: optionFromUndefined(withApplicantIndex(t.unknown)),
  outputCodec: t.void,
});
