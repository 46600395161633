import * as t from "io-ts";
import { apiCall } from "./APICall";
import { TokenInfo, AuthenticationInfo } from "design-system";
import { eq } from "fp-ts";
import { constFalse, constTrue } from "fp-ts/function";
import { UUID } from "io-ts-types/lib/UUID";
import { LoginOutput } from "./Login/api";

export const anonymousToken = apiCall({
  inputEq: eq.fromEquals(constTrue),
  path: ["authorization", "anonymous", "token"],
  inputCodec: t.void,
  outputCodec: AuthenticationInfo,
});

export const potentialClientRemoteToken = apiCall({
  inputEq: eq.fromEquals(constTrue),
  path: ["authorization", "potentialClient", "remote", "noauth", "token"],
  inputCodec: t.void,
  outputCodec: AuthenticationInfo,
});

export const potentialClientToken = apiCall({
  inputEq: eq.fromEquals(constFalse),
  path: ["authorization", "potentialClient", "noauth", "token"],
  inputCodec: t.type({
    flowId: UUID,
  }),
  outputCodec: TokenInfo,
});

const SSOLoginInput = t.type(
  {
    jwtReference: UUID,
  },
  "SSOLoginInput"
);
const eqSSOLoginInput = eq.getStructEq({ jwtReference: eq.eqString });

export const ssoLogin = apiCall({
  path: ["authorization", "client", "sso", "token"],
  inputCodec: SSOLoginInput,
  inputEq: eqSSOLoginInput,
  outputCodec: LoginOutput,
});

const GenerateIdentifiedClientTokenOutput = t.type(
  {
    flowId: UUID,
  },
  "GenerateIdentifiedClientTokenOutput"
);

export const generateIdentifiedClientToken = apiCall({
  path: ["authorization", "client", "sso", "generateIdentifiedClientToken"],
  inputCodec: t.void,
  inputEq: eq.fromEquals(constFalse),
  outputCodec: GenerateIdentifiedClientTokenOutput,
});
