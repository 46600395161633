import { AlertDialog, unsafeLocalizedString, WarningIcon } from "design-system";

type Props = {
  onCancel: () => unknown;
  onDismiss: () => unknown;
};

export function NewVersionAlert(props: Props) {
  return (
    <AlertDialog
      title={unsafeLocalizedString("New Version Available")}
      message={unsafeLocalizedString(
        "You are not running the latest version of the application, please reload the tab(CTRL+R) " +
          "when possible or press RELOAD NOW(these actions will restart the flow). This warning is shown only in SIT/UAT/HF."
      )}
      confirmLabel={unsafeLocalizedString("RELOAD NOW")}
      cancelLabel={unsafeLocalizedString("OK")}
      onDismiss={props.onDismiss}
      type={"confirmation"}
      onConfirm={() => window.location.reload()}
      onCancel={props.onCancel}
      icon={WarningIcon}
    />
  );
}
