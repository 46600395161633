import * as t from "io-ts";
import { eq } from "fp-ts";
import { constFalse } from "fp-ts/function";
import { apiCall } from "../APICall";
import { optionFromNullable } from "io-ts-types/lib/optionFromNullable";
// import {LocalizedString} from "design-system";

export const PhysicalCardInput = t.type(
  {
    checkboxValue: t.boolean,
  },
  "PhysicalCardInput"
);

export type PhysicalCardInput = t.TypeOf<typeof PhysicalCardInput>;

export const GetCurrentPackageTypeResponse = t.type(
  {
    packageType: t.string,
  },
  "GetCurrentPackageTypeResponse"
);

export type GetCurrentPackageTypeResponse = t.TypeOf<
  typeof GetCurrentPackageTypeResponse
>;

export const InitVirtualCardResponse = t.type(
  {
    packageType: t.string,
    physicalCardSelected: optionFromNullable(t.boolean),
  },
  "InitVirtualCardResponse"
);

export type InitVirtualCardResponse = t.TypeOf<typeof InitVirtualCardResponse>;

export const saveCardTypeSelection = apiCall({
  path: ["packages", "uKonto", "cardTypeSelection"],
  inputEq: eq.fromEquals(constFalse),
  inputCodec: t.void,
  outputCodec: t.unknown,
});
