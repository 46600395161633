import { parse, Parser, Route } from "fp-ts-routing";

export function parseLocation<T>(
  path: string,
  router: Parser<T>,
  fallback: T
): T {
  const s = path
    .replace(/^.+\.html/, "") // this is to support local dev (no virtual host internet/intranet)
    .replace(/\+/g, "&"); // see SBL-20671
  return parse(router, Route.parse(s), fallback);
}
