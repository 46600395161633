import { option, nonEmptyArray, record } from "fp-ts";
import { constant, constFalse, pipe } from "fp-ts/function";
import { NonEmptyString } from "io-ts-types/lib/NonEmptyString";
import { Option } from "fp-ts/Option";
import { FileContent } from "design-system";
import { ClientDataOCR } from "../IdUpload/domain";
import { ExtractedDataResult } from "./types";
import { MobileFlowType } from "./state";
import { LocaleKey } from "../intl";
import { DocumentTypeUpload } from "./domain";

export const srcFromBase64 = (
  base64Content: FileContent,
  fileName: NonEmptyString = ".jpeg" as NonEmptyString
): Option<NonEmptyString> =>
  pipe(
    fileName.match(/\w+.(\w+)$/),
    option.fromNullable,
    option.chain(nonEmptyArray.fromArray),
    option.map(matches => {
      const imageType = nonEmptyArray.head(matches);
      return `data:image/${
        imageType === "jpg" ? "jpeg" : imageType
      };base64,${base64Content}` as NonEmptyString;
    })
  );

export const mergeExtractDataDocumentsOutput = (
  primary: Option<ClientDataOCR>,
  secondary: Option<ClientDataOCR>
): Option<ExtractedDataResult> => {
  const isThereAnAdditionalDocument =
    option.isSome(primary) && option.isSome(secondary);
  return pipe(
    primary,
    option.alt(constant(secondary)),
    option.map(document => ({
      documentType: document.documentType,
      additionalDocumentType: pipe(
        secondary,
        option.map(document => document.documentType),
        option.filter(constant(isThereAnAdditionalDocument))
      ),
      personalData: document.personalData,
      permanentAddress: document.permanentAddress,
      documentDetails: document.documentDetails,
      addressSuggestions: document.addressSuggestions,
      additionalDocumentDetails: pipe(
        secondary,
        option.map(document => document.documentDetails),
        option.filter(constant(isThereAnAdditionalDocument))
      ),
      validationErrors: document.validationErrors,
    }))
  );
};

export function foldMobileFlowType<T>(
  type: MobileFlowType,
  match: {
    [k in MobileFlowType]: () => T;
  }
) {
  return match[type]();
}

export function hasValidationErrors(
  primary: Option<ClientDataOCR>,
  secondary: Option<ClientDataOCR>
): boolean {
  const documentHasErrors = (document: Option<ClientDataOCR>) =>
    pipe(
      document,
      option.fold(constFalse, item =>
        pipe(
          item.validationErrors,
          option.fold(constFalse, record.some(option.isSome))
        )
      )
    );
  return documentHasErrors(primary) || documentHasErrors(secondary);
}

export function formatDocumentType(
  documentType: DocumentTypeUpload
): LocaleKey {
  switch (documentType) {
    case "IDCard":
      return `Identification.UploadDocuments.step1.validDocuments.IDCard`;
    case "Passport":
      return `Identification.UploadDocuments.step1.validDocuments.Passport`;
    case "DrivingLicenseCZ":
      return `Identification.UploadDocuments.step1.validDocuments.DrivingLicenseCZ`;
    case "DrivingLicenseSK":
      return `Identification.UploadDocuments.step1.validDocuments.DrivingLicenseSK`;
    case "LongTermResidencePermit":
      return `Identification.UploadDocuments.step1.validDocuments.LongTermResidencePermit`;
    case "PermanentResidencePermit":
      return `Identification.UploadDocuments.step1.validDocuments.PermanentResidencePermit`;
    case "TemporaryResidencePermit":
      return `Identification.UploadDocuments.step1.validDocuments.TemporaryResidencePermit`;
  }
}
