import { useState } from "react";
import {
  RadioGroup,
  FileIcon,
  SmartphoneIcon,
  Dialog,
  Children,
  LocalizedString,
  unsafeLocalizedString,
} from "design-system";
import { useFormatMessage } from "../intl";
import { option } from "fp-ts";
import { NonEmptyArray } from "fp-ts/NonEmptyArray";
import { identity, flow } from "fp-ts/function";
import { useAppContext } from "../useAppContext";
import { UploadMode } from "./state";
import { usePortalStatusContext } from "../PortalStatusContext";

type Props = {
  onConfirm: ({ mode }: { mode: UploadMode }) => unknown;
  onDismiss: () => unknown;
  mobileFeatureStatus: "hidden" | "disabled" | "enabled";
};

export function UploadModeDialog(props: Props) {
  const formatMessage = useFormatMessage();
  const {
    config: { mockScanner },
  } = useAppContext();
  const { portalBlocked } = usePortalStatusContext();

  const formatUploadModeTitle = (uploadMode: UploadMode): LocalizedString => {
    switch (uploadMode) {
      case "Scanner":
        return formatMessage("Identification.UploadDocuments.scannerModeTitle");
      case "Mobile":
        return formatMessage("Identification.UploadDocuments.mobileModeTitle");
      case "_MockScanner":
        return unsafeLocalizedString("Do you want to use the Fake Scanner?");
    }
  };

  const formatUploadModeDescription = (
    uploadMode: UploadMode
  ): LocalizedString => {
    switch (uploadMode) {
      case "Scanner":
        return formatMessage(
          "Identification.UploadDocuments.scannerModeDescription"
        );
      case "Mobile":
        return formatMessage(
          "Identification.UploadDocuments.mobileModeDescription"
        );
      case "_MockScanner":
        return unsafeLocalizedString(
          "(temporary)  You'll be able to upload the predefined mock documents only"
        );
    }
  };

  const formatUploadModeIcon = (uploadMode: UploadMode): Children => {
    switch (uploadMode) {
      case "Scanner":
        return <FileIcon size="medium" />;
      case "Mobile":
        return <SmartphoneIcon size="medium" />;
      case "_MockScanner":
        return <FileIcon size="medium" />;
    }
  };

  const [mode, setMode] = useState<UploadMode>("Scanner");

  const options: NonEmptyArray<UploadMode> = ["Scanner"];

  if (props.mobileFeatureStatus !== "hidden") {
    options.push("Mobile");
  }

  if (mockScanner) {
    options.push("_MockScanner");
  }

  return (
    <Dialog
      variant="left"
      size="medium"
      title={formatMessage("Identification.UploadDocuments.modeModalTitle")}
      onDismiss={option.some(props.onDismiss)}
      actions={
        !portalBlocked
          ? [
              {
                variant: "primary",
                action: () => props.onConfirm({ mode }),
                label: formatMessage(
                  "Identification.UploadDocuments.modeModalConfirmLabel"
                ),
              },
            ]
          : []
      }
    >
      <RadioGroup
        name="uploadMode"
        variant="vertical"
        value={option.some(mode)}
        onChange={option.map(setMode)}
        options={options}
        optionKey={identity}
        isOptionDisabled={option =>
          option === "Mobile" && props.mobileFeatureStatus === "disabled"
        }
        renderOption={formatUploadModeTitle}
        renderOptionChildren={flow(formatUploadModeDescription, option.some)}
        renderOptionIcon={flow(formatUploadModeIcon, option.some)}
        issuesType={option.none}
        disabled={portalBlocked}
      />
    </Dialog>
  );
}
