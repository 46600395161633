import { useContext } from "react";
import { SnackBarContext } from "design-system";

export const useSnackBar = () => {
  const ctx = useContext(SnackBarContext);
  if (!ctx) {
    throw new Error("Snackbar context is not provided");
  }
  return ctx;
};
