import * as remoteData from "./RemoteData";
import { RemoteData } from "./RemoteData";
import { TaskEither } from "fp-ts/TaskEither";
import { useState, useEffect } from "react";
import { pipe } from "fp-ts/function";

export function useRemoteData<E, A>(task: TaskEither<E, A>): RemoteData<E, A> {
  const [data, setData] = useState<RemoteData<E, A>>(remoteData.remoteLoading);
  useEffect(() => {
    let isActive = true;
    setData(data =>
      pipe(
        data,
        remoteData.fold(
          () => remoteData.remoteLoading,
          () => remoteData.remoteLoading,
          data => remoteData.remoteSuccess(data, true)
        )
      )
    );
    task().then(data => {
      if (isActive) {
        setData(remoteData.fromEither(data));
      }
    });
    return () => {
      isActive = false;
    };
  }, [task]);
  return data;
}
