import { NewVersionAlert } from "./NewVersionAlert";
import { useVersionCheck } from "./useVersionCheck";

type Props = {
  children: JSX.Element;
};

export function VersionChecker(props: Props) {
  const { shouldShowNewVersionAlert, onCancelAlert } = useVersionCheck();

  return (
    <>
      {shouldShowNewVersionAlert && (
        <NewVersionAlert onCancel={onCancelAlert} onDismiss={onCancelAlert} />
      )}
      {props.children}
    </>
  );
}
