export function setupLogging() {
  if (typeof window === undefined || typeof console === undefined) return;

  (window as any).eShopLogs = [];
  const logs: Array<unknown> = (window as any).eShopLogs;

  window.onerror = message => {
    logs.push("onerror:");
    logs.push(message);
  };
  ["log", "debug", "info", "warn", "error"].forEach(fn => {
    const originalFn =
      typeof (console as any)[fn] === "function"
        ? (console as any)[fn].bind(console)
        : () => {};
    (console as any)[fn] = (...args: Array<unknown>) => {
      logs.push(`${fn}:`);
      logs.push(...args);
      return originalFn(...args);
    };
  });
}
