import * as t from "io-ts";
import { Eq } from "fp-ts/Eq";
import { Option } from "fp-ts/Option";
import { boolean, either, eq, nonEmptyArray, option } from "fp-ts";
import { option as optionCodec } from "io-ts-types/lib/option";
import { failure } from "io-ts/lib/PathReporter";
import { constFalse, identity, Lazy, pipe } from "fp-ts/function";
import {
  FileContent,
  LocalizedString,
  NonNegative,
  NonNegativeInteger,
  Percentage,
  Positive,
  PositiveInteger,
} from "design-system";
import { gzip, ungzip } from "pako";
import { nonEmptyArray as nonEmptyArrayCodec } from "io-ts-types/lib/nonEmptyArray";
import { NonEmptyArray } from "fp-ts/NonEmptyArray";
import { PortalStatus } from "./PortalStatusContext";
import { Reader } from "fp-ts/Reader";
import { IO } from "fp-ts/IO";
import { useAppContext } from "./useAppContext";
import moment from "moment";

export const Tenant = t.keyof({ CZ: true, SK: true }, "Tenant");
export type Tenant = t.TypeOf<typeof Tenant>;

export function foldTenant<R>(tenant: Tenant, onSK: () => R, onCZ: () => R): R {
  switch (tenant) {
    case "CZ":
      return onCZ();
    case "SK":
      return onSK();
  }
}

export const eqTenant: Eq<Tenant> = eq.eqStrict;

export function useCheckTenant() {
  const {
    apiParameters: { tenant },
  } = useAppContext();

  type Pattern = { [k in Tenant]: Lazy<boolean> };

  return function checkTenant(partialPattern: Partial<Pattern>) {
    const defaultPattern: Pattern = {
      CZ: constFalse,
      SK: constFalse,
    };

    const pattern = { ...defaultPattern, ...partialPattern };

    return foldTenant(tenant, pattern["SK"], pattern["CZ"]);
  };
}

export const Channel = t.keyof(
  {
    TLS_Remote: true,
    PWS_Remote: true,
    SB_Remote: true,
    OB_Remote: true,
    Branch_InPerson: true,
    "3P_InPerson": true,
    Franchise_InPerson: true,
  },
  "Channel"
);
export type Channel = t.TypeOf<typeof Channel>;

export function foldChannel<R>(
  matches: { [C in Channel]: () => R }
): (channel: Channel) => R {
  return channel => matches[channel]();
}

export function foldChannelLocation<R>(matches: {
  Remote: () => R;
  InPerson: () => R;
}) {
  return foldChannel({
    Branch_InPerson: matches.InPerson,
    "3P_InPerson": matches.InPerson,
    Franchise_InPerson: matches.InPerson,
    OB_Remote: matches.Remote,
    PWS_Remote: matches.Remote,
    SB_Remote: matches.Remote,
    TLS_Remote: matches.InPerson,
  });
}

export const eqChannel: Eq<Channel> = eq.eqStrict;

export const SupportedLocales = t.keyof({ en: null, cs: null, sk: null });
export type SupportedLocales = t.TypeOf<typeof SupportedLocales>;

export const APIParameters = t.type(
  {
    tenant: Tenant,
    channel: Channel,
    language: SupportedLocales,
  },
  "APIParameters"
);

export type APIParameters = t.TypeOf<typeof APIParameters>;

export { Positive, NonNegative, PositiveInteger };

export function unsafeNonNegativeInteger(n: number): NonNegativeInteger {
  return pipe(
    NonNegativeInteger.decode(n),
    either.fold(errors => {
      throw new Error(
        `Invalid non negative integer provided: ${failure(errors).join("\n")}`
      );
    }, identity)
  );
}

export type HTMLContentBrand = string & {
  readonly HTMLContent: unique symbol;
};

export const HTMLContent = t.brand(
  t.string,
  (str): str is t.Branded<string, HTMLContentBrand> => str.length > 0,
  "HTMLContent"
);

export type HTMLContent = t.TypeOf<typeof HTMLContent>;

export const CompressedFileContent = new t.Type<FileContent, string, unknown>(
  "CompressedFileContent",
  FileContent.is,
  (u, c) =>
    pipe(
      t.string.validate(u, c),
      either.chain(s => {
        try {
          const decompressed = ungzip(atob(s), { to: "string" });
          return decompressed
            ? t.success(decompressed)
            : t.failure(u, c, "Unable to decompress.");
        } catch (e) {
          return t.failure(u, c, "Unable to decompress.");
        }
      }),
      either.chain(FileContent.decode)
    ),
  a => btoa(gzip(a, { to: "string" }))
);

export type CompressedFileContent = t.TypeOf<typeof CompressedFileContent>;

export const Currency = t.keyof(
  {
    CZK: true,
    EUR: true,
  },
  "Currency"
);

export function foldCurrency<T>(
  matches: { [k in Currency]: IO<T> }
): Reader<Currency, T> {
  return currency => matches[currency]();
}

export type Currency = t.TypeOf<typeof Currency>;
export const eqCurrency: Eq<Currency> = eq.eqStrict;

export interface OptionFromUndefined<C extends t.Mixed>
  extends t.Type<Option<t.TypeOf<C>>, t.OutputOf<C> | undefined, unknown> {}

export function optionFromUndefined<C extends t.Mixed>(
  codec: C,
  name: string = `Option<${codec.name}>`
): OptionFromUndefined<C> {
  return new t.Type(
    name,
    optionCodec(codec).is,
    (value, context) =>
      value == null
        ? t.success(option.none)
        : pipe(codec.validate(value, context), either.map(option.some)),
    value => option.toUndefined(option.option.map(value, codec.encode))
  );
}

type Optional<P extends t.Props> = t.TypeC<
  {
    [K in keyof P]: OptionFromUndefined<P[K]>;
  }
>;

export function optional<P extends t.Props>(
  props: P,
  name?: string
): Optional<P> {
  const fields = {} as { [K in keyof P]: OptionFromUndefined<P[K]> };

  for (const key in props) {
    fields[key] = optionFromUndefined(props[key]);
  }

  return t.type(fields, name);
}

export interface OptionFromArray<C extends t.Mixed>
  extends t.Type<
    Option<NonEmptyArray<t.TypeOf<C>>>,
    t.OutputOf<C>[] | undefined,
    unknown
  > {}

export function optionFromArray<C extends t.Mixed>(
  codec: C,
  name: string = `OptionArray<${codec.name}>`
): OptionFromArray<C> {
  return new t.Type(
    name,
    optionCodec(nonEmptyArrayCodec(codec)).is,
    (value, context) =>
      value == null
        ? t.success(option.none)
        : pipe(
            t.array(codec).validate(value, context),
            either.map(nonEmptyArray.fromArray)
          ),
    value =>
      option.toUndefined(
        option.option.map(value, nonEmptyArrayCodec(codec).encode)
      )
  );
}

export const MoneyAmount = t.type({
  amount: t.number,
  currency: Currency,
});

export type MoneyAmount = t.TypeOf<typeof MoneyAmount>;

export const eqMoneyAmount: Eq<MoneyAmount> = eq.getStructEq({
  amount: eq.eqStrict,
  currency: eq.eqStrict,
});

const PriceFrequency = t.keyof({
  OneTime: true,
  PerMonth: true,
});
export type PriceFrequency = t.TypeOf<typeof PriceFrequency>;

const APIPrice = t.type(
  {
    moneyAmount: MoneyAmount,
    frequency: t.keyof({ PerMonth: true, OneTime: true }, "Frequency"),
  },
  "Price"
);
type APIPrice = t.TypeOf<typeof APIPrice>;

type FreePrice = { type: "free" };
type AmountPrice = {
  type: "amount";
  moneyAmount: MoneyAmount;
  frequency: PriceFrequency;
};
export type Price = FreePrice | AmountPrice;

export const freePrice: Price = {
  type: "free",
};

export function amountPrice(
  moneyAmount: MoneyAmount,
  frequency: PriceFrequency
): Price {
  return { type: "amount", moneyAmount, frequency };
}

export function foldPrice<R>(match: {
  Free: () => R;
  Amount: (moneyAmount: MoneyAmount, frequency: PriceFrequency) => R;
}): (price: Price) => R {
  return price => {
    switch (price.type) {
      case "free":
        return match.Free();
      case "amount":
        return match.Amount(price.moneyAmount, price.frequency);
    }
  };
}

function priceFromAPIPrice(price: APIPrice): Price {
  return pipe(
    price.moneyAmount.amount > 0,
    boolean.fold(
      () => freePrice,
      () => amountPrice(price.moneyAmount, price.frequency)
    )
  );
}

export const Price = new t.Type<Price, unknown>(
  "PriceFromAPIPrice",
  (_): _ is Price => true, // unused in practice
  (v, c) => pipe(APIPrice.validate(v, c), either.map(priceFromAPIPrice)),
  foldPrice({
    Free: () =>
      APIPrice.encode({
        moneyAmount: { amount: unsafeNonNegativeInteger(0), currency: "EUR" },
        frequency: "OneTime",
      }),
    Amount: ({ amount, currency }, frequency) =>
      APIPrice.encode({ moneyAmount: { amount, currency }, frequency }),
  })
);

export interface PercentageLikeFromNumberC
  extends t.Type<number, number, unknown> {}

export const PercentageLikeFromNumber: PercentageLikeFromNumberC = new t.Type<
  number,
  number,
  unknown
>(
  "PercentageLikeFromNumber",
  Percentage.is,
  (u, c) =>
    pipe(
      t.number.validate(u, c),
      either.map(n => n / 100)
    ),
  n => n * 100
);

export type MonthBrand = {
  readonly Month: unique symbol;
};
export const Month = t.brand(
  PositiveInteger,
  (m): m is t.Branded<PositiveInteger, MonthBrand> => m <= 12,
  "Month"
);
export type Month = t.TypeOf<typeof Month>;

export const MonthYear = t.type(
  {
    year: PositiveInteger,
    month: Month,
  },
  "MonthYear"
);
export type MonthYear = t.TypeOf<typeof MonthYear>;

export const eqMonthYear = eq.getStructEq({
  year: eq.eqNumber,
  month: eq.eqNumber,
});

export const MoneyAmountDateInterval = t.type(
  {
    amount: MoneyAmount,
    startDate: MonthYear,
    endDate: MonthYear,
  },
  "MoneyAmountDateInterval"
);
export type MoneyAmountDateInterval = t.TypeOf<typeof MoneyAmountDateInterval>;

export const GenericError = t.type({
  id: t.literal("GenericError"),
});
export type GenericError = t.TypeOf<typeof GenericError>;
export const genericError: GenericError = { id: "GenericError" };

export const PortalBlocked = t.type({
  id: t.literal("PortalBlocked"),
});
export type PortalBlocked = t.TypeOf<typeof PortalBlocked>;
export const portalBlocked: PortalBlocked = { id: "PortalBlocked" };

export const UnauthorizedError = t.type(
  { id: t.literal("Unauthorized") },
  "UnauthorizedError"
);
export type UnauthorizedError = t.TypeOf<typeof UnauthorizedError>;
export const unauthorizedError: UnauthorizedError = { id: "Unauthorized" };

const leadZero = (n: number): string => n.toString().padStart(2, "0");

const formattedDatePattern = /^\d{2}\.\d{2}\.\d{4}$/;

export interface DateFromFormattedDateC extends t.Type<Date, string, unknown> {}

export const DateFromFormattedDate: DateFromFormattedDateC = new t.Type<
  Date,
  string,
  unknown
>(
  "DateFromFormattedDate",
  (u): u is Date => u instanceof Date,
  (u, c) =>
    pipe(
      t.string.validate(u, c),
      either.chain(s => {
        if (!formattedDatePattern.test(s)) {
          return t.failure(u, c);
        }

        const [day, month, year] = s
          .split(".")
          .map((v: string) => parseInt(v, 10));

        return t.success(new Date(year, month - 1, day));
      })
    ),
  d => {
    return [
      leadZero(d.getDate()),
      leadZero(d.getMonth() + 1),
      d.getFullYear(),
    ].join(".");
  }
);

const sqlStringDatePattern = /^\d{4}-\d{2}-\d{2}$/;

export interface DateFromSQLStringC extends t.Type<Date, string, unknown> {}

export const DateFromSQLString: DateFromSQLStringC = new t.Type<
  Date,
  string,
  unknown
>(
  "DateFromSQLString",
  (u): u is Date => u instanceof Date,
  (u, c) =>
    pipe(
      t.string.validate(u, c),
      either.chain(s => {
        if (!sqlStringDatePattern.test(s)) {
          return t.failure(u, c);
        }

        const date = moment.utc(s, "YYYY-MM-DD");

        return t.success(date.toDate());
      })
    ),
  d => {
    return [
      d.getFullYear(),
      leadZero(d.getMonth() + 1),
      leadZero(d.getDate()),
    ].join("-");
  }
);

export type CountryCodeBrand = string & {
  readonly CountryCode: unique symbol;
};

export const CountryCode = t.brand(
  t.string,
  (str): str is t.Branded<string, CountryCodeBrand> => str.length === 3,
  "CountryCode"
);
export type CountryCode = t.TypeOf<typeof CountryCode>;

export const CoApplicant = t.type({
  index: PositiveInteger,
});
export type CoApplicant = t.TypeOf<typeof CoApplicant>;
export const eqCoApplicant = eq.getStructEq({
  index: eq.eqNumber,
});

export const CoApplicantInput = t.type({
  coApplicant: optionFromUndefined(CoApplicant),
});
export type CoApplicantInput = t.TypeOf<typeof CoApplicantInput>;
export const eqCoApplicantInput = eq.getStructEq({
  coApplicant: option.getEq(eqCoApplicant),
});

export function withCoApplicant<T extends t.Props>(type: t.TypeC<T>) {
  return t.type(
    {
      ...type.props,
      ...CoApplicantInput.props,
    },
    `WithCoapplicant(${type.name})`
  );
}

export function eqWithCoApplicant<O>(codecEq: Eq<O>): Eq<O & CoApplicantInput> {
  return eq.fromEquals(
    (
      { coApplicant: coApplicantA, ...a },
      { coApplicant: coApplicantB, ...b }
    ) =>
      codecEq.equals(a as any, b as any) &&
      option.getEq(eqCoApplicant).equals(coApplicantA, coApplicantB)
  );
}

export const Sex = t.keyof({
  M: true,
  F: true,
});
export type Sex = t.TypeOf<typeof Sex>;

export const DocumentPurpose = t.keyof({
  Primary: true,
  Secondary: true,
});
export type DocumentPurpose = t.TypeOf<typeof DocumentPurpose>;

export const DocumentIdentificationFlow = t.keyof({
  Primary: true,
  Secondary: true,
  PrimaryAndSecondary: true,
  PrimaryAndLivenessCheck: true,
});
export type DocumentIdentificationFlow = t.TypeOf<
  typeof DocumentIdentificationFlow
>;

export function foldDocumentIdentificationFlow<T>(
  documentIdentificationFlow: DocumentIdentificationFlow,
  match: { [k in DocumentIdentificationFlow]: () => T }
): T {
  return match[documentIdentificationFlow]();
}

export function isSingleDocumentPurpose(
  flowType: DocumentIdentificationFlow
): flowType is DocumentPurpose {
  return flowType === "Primary" || flowType === "Secondary";
}

export const UploadDocumentFlowType = t.keyof({
  PersonalProfile: true,
  MORTGAGE: true,
  CL: true,
  SL: true,
  TL: true,
  RPL: true,
  RL: true,
});

export type UploadDocumentFlowType = t.TypeOf<typeof UploadDocumentFlowType>;

const InvalidFlowId = t.type({
  id: t.literal("InvalidFlowId"),
});
const InvalidUUIDError = t.type({
  id: t.literal("InvalidLink"),
});
const LinkExpiredError = t.type({
  id: t.literal("ExpiredLink"),
});
export const VerifyLinkError = t.union([
  GenericError,
  InvalidUUIDError,
  LinkExpiredError,
  InvalidFlowId,
]);
export type VerifyLinkError = t.TypeOf<typeof VerifyLinkError>;

const BaseMaritalStatus = {
  Single: true,
  Married: true,
  Divorced: true,
  Widowed: true,
};

export const MaritalStatusCZ = t.keyof({
  ...BaseMaritalStatus,
  RegisteredPartnership: true,
});
export type MaritalStatusCZ = t.TypeOf<typeof MaritalStatusCZ>;

export const MaritalStatusSK = t.keyof(BaseMaritalStatus);
export type MaritalStatusSK = t.TypeOf<typeof MaritalStatusSK>;
export const MaritalStatus = t.union([MaritalStatusCZ, MaritalStatusSK]);
export type MaritalStatus = t.TypeOf<typeof MaritalStatus>;

export const ExistingClientAuthenticationMethod = t.keyof({
  ID: true,
  QR: true,
  PUSH_NOTIFICATION: true,
  EXTERNAL_PUSH_NOTIFICATION: true,
  PIN: true,
});
export type ExistingClientAuthenticationMethod = t.TypeOf<
  typeof ExistingClientAuthenticationMethod
>;

export function foldExistingClientAuthenticationMethod<R>(
  method: ExistingClientAuthenticationMethod,
  matches: {
    [K in ExistingClientAuthenticationMethod]: () => R;
  }
): R {
  return matches[method]();
}

export const DesktopAuthenticationMethod = t.keyof({
  ID: true,
  QR: true,
  PUSH_NOTIFICATION: true,
  EXTERNAL_PUSH_NOTIFICATION: true,
});
export type DesktopAuthenticationMethod = t.TypeOf<
  typeof DesktopAuthenticationMethod
>;

export function foldDesktopAuthenticationMethod<T>(
  matches: {
    [k in DesktopAuthenticationMethod]: IO<T>;
  }
): Reader<DesktopAuthenticationMethod, T> {
  return method => matches[method]();
}

export function foldPortalStatus<T>(
  portalStatus: PortalStatus,
  match: {
    onInitial: () => T;
    onNone: () => T;
    onActive: (message: LocalizedString) => T;
    onScheduled: (message: LocalizedString) => T;
  }
) {
  switch (portalStatus.type) {
    case "initial":
      return match.onInitial();
    case "none":
      return match.onNone();
    case "current":
      return match.onActive(portalStatus.message);
    case "scheduled":
      return match.onScheduled(portalStatus.message);
  }
}

export const ForeignCountries = t.keyof({
  BGD: true,
  BEL: true,
  BFA: true,
  BGR: true,
  BIH: true,
  BRB: true,
  WLF: true,
  BLM: true,
  BMU: true,
  BRN: true,
  BOL: true,
  BHR: true,
  BDI: true,
  BEN: true,
  BTN: true,
  JAM: true,
  BVT: true,
  BWA: true,
  WSM: true,
  BES: true,
  BRA: true,
  BHS: true,
  JEY: true,
  BLR: true,
  BLZ: true,
  RUS: true,
  RWA: true,
  SRB: true,
  TLS: true,
  REU: true,
  TKM: true,
  TJK: true,
  ROU: true,
  TKL: true,
  GNB: true,
  GUM: true,
  GTM: true,
  SGS: true,
  GRC: true,
  GNQ: true,
  GLP: true,
  JPN: true,
  GUY: true,
  GGY: true,
  GUF: true,
  GEO: true,
  GRD: true,
  GBR: true,
  GAB: true,
  SLV: true,
  GIN: true,
  GMB: true,
  GRL: true,
  GIB: true,
  GHA: true,
  OMN: true,
  TUN: true,
  JOR: true,
  HRV: true,
  HTI: true,
  HUN: true,
  HKG: true,
  HND: true,
  HMD: true,
  VEN: true,
  PRI: true,
  PSE: true,
  PLW: true,
  PRT: true,
  SJM: true,
  PRY: true,
  IRQ: true,
  PAN: true,
  PYF: true,
  PNG: true,
  PER: true,
  PAK: true,
  PHL: true,
  PCN: true,
  POL: true,
  SPM: true,
  ZMB: true,
  ESH: true,
  EST: true,
  EGY: true,
  ZAF: true,
  ECU: true,
  ITA: true,
  VNM: true,
  SLB: true,
  ETH: true,
  SOM: true,
  ZWE: true,
  SAU: true,
  ESP: true,
  ERI: true,
  MNE: true,
  MDA: true,
  MDG: true,
  MAF: true,
  MAR: true,
  MCO: true,
  UZB: true,
  MMR: true,
  MLI: true,
  MAC: true,
  MNG: true,
  MHL: true,
  MKD: true,
  MUS: true,
  MLT: true,
  MWI: true,
  MTQ: true,
  MNP: true,
  MSR: true,
  MRT: true,
  IMN: true,
  UGA: true,
  TZA: true,
  MYS: true,
  MEX: true,
  ISR: true,
  FRA: true,
  IOT: true,
  SHN: true,
  FIN: true,
  FJI: true,
  FLK: true,
  FSM: true,
  FRO: true,
  NIC: true,
  NLD: true,
  NOR: true,
  NAM: true,
  VUT: true,
  NCL: true,
  NER: true,
  NFK: true,
  NGA: true,
  NZL: true,
  NPL: true,
  NRU: true,
  NIU: true,
  COK: true,
  CIV: true,
  CHE: true,
  COL: true,
  CHN: true,
  CMR: true,
  CHL: true,
  CCK: true,
  CAN: true,
  COG: true,
  CAF: true,
  COD: true,
  CYP: true,
  CXR: true,
  CRI: true,
  CUW: true,
  CPV: true,
  CUB: true,
  SWZ: true,
  SYR: true,
  KGZ: true,
  KEN: true,
  SSD: true,
  SUR: true,
  KIR: true,
  KHM: true,
  KNA: true,
  COM: true,
  STP: true,
  KOR: true,
  SVN: true,
  PRK: true,
  KWT: true,
  SEN: true,
  SMR: true,
  SLE: true,
  SYC: true,
  KAZ: true,
  CYM: true,
  SGP: true,
  SWE: true,
  SDN: true,
  DOM: true,
  DMA: true,
  DJI: true,
  DNK: true,
  VGB: true,
  DEU: true,
  YEM: true,
  DZA: true,
  USA: true,
  URY: true,
  MYT: true,
  UMI: true,
  LBN: true,
  LCA: true,
  LAO: true,
  TUV: true,
  TWN: true,
  TTO: true,
  TUR: true,
  LKA: true,
  LIE: true,
  LVA: true,
  TON: true,
  LTU: true,
  LUX: true,
  LBR: true,
  LSO: true,
  THA: true,
  ATF: true,
  TGO: true,
  TCD: true,
  TCA: true,
  LBY: true,
  VAT: true,
  VCT: true,
  ARE: true,
  AND: true,
  ATG: true,
  AFG: true,
  AIA: true,
  VIR: true,
  ISL: true,
  IRN: true,
  ARM: true,
  ALB: true,
  AGO: true,
  ATA: true,
  ASM: true,
  ARG: true,
  AUS: true,
  AUT: true,
  ABW: true,
  IND: true,
  ALA: true,
  AZE: true,
  IRL: true,
  IDN: true,
  UKR: true,
  QAT: true,
  MOZ: true,
});
