import { useRemoteData } from "./useRemoteData";
import { useAPIEndpoint } from "./useAPIEndpoint";
import { either, option, taskEither } from "fp-ts";
import { useEffect, useMemo, useState } from "react";
import {
  constFalse,
  constTrue,
  constVoid,
  identity,
  pipe,
} from "fp-ts/function";
import { APICallImplementation } from "./APICall";
import * as remoteData from "./RemoteData";
import { RemoteData } from "./RemoteData";
import { TaskEither } from "fp-ts/TaskEither";
import { ReaderTaskEither } from "fp-ts/ReaderTaskEither";
import {
  GenericError,
  genericError,
  PortalBlocked,
  UnauthorizedError,
} from "./globalDomain";
import { useAppContext } from "./useAppContext";
import {
  LocalizedString,
  useAuthenticationContext,
  useLoadingStatusWrite,
  useStableEquality,
} from "design-system";

import { usePortalStatusContext } from "./PortalStatusContext";
import { useTimeoutFn } from "react-use";

type APIError<E> =
  | E
  | GenericError
  | UnauthorizedError
  | PortalBlocked
  | LocalizedString;

type RefinedAPIError<E> = Exclude<
  APIError<E>,
  UnauthorizedError | PortalBlocked | LocalizedString
>;

function handleFailure<E, A>(
  onUnauthorized: () => unknown,
  onBlockedPortal: () => unknown
) {
  return (e: APIError<E>) =>
    pipe(
      e,
      UnauthorizedError.decode,
      either.fold(
        () =>
          pipe(
            e,
            PortalBlocked.decode,
            either.fold(
              () => taskEither.left<RefinedAPIError<E>, A>(e as any),
              () =>
                pipe(
                  taskEither.fromIO<RefinedAPIError<E>, unknown>(() =>
                    onBlockedPortal()
                  ),
                  taskEither.chain(() =>
                    taskEither.left<RefinedAPIError<E>, A>(genericError)
                  )
                )
            )
          ),

        () =>
          pipe(
            taskEither.fromIO<RefinedAPIError<E>, unknown>(onUnauthorized),
            taskEither.chain(() =>
              taskEither.left<RefinedAPIError<E>, A>(genericError)
            )
          )
      )
    );
}

interface UseCommandConfig {
  skipTracking?: boolean;
}

export function useCommand<E, A>(
  apiCall: APICallImplementation<void, E, A>,
  config?: UseCommandConfig
): ReaderTaskEither<void, RefinedAPIError<E>, A>;
export function useCommand<R, E, A>(
  apiCall: APICallImplementation<R, E, A>,
  config?: UseCommandConfig
): ReaderTaskEither<R, RefinedAPIError<E>, A>;
export function useCommand<R, E, A>(
  apiCall: APICallImplementation<R, E, A>,
  config: UseCommandConfig = {}
): ReaderTaskEither<R, RefinedAPIError<E>, A> {
  const apiEndpoint = useAPIEndpoint();
  const { apiParameters } = useAppContext();
  const { trackSubmission } = useLoadingStatusWrite();

  const { authInfo, refreshToken, logout } = useAuthenticationContext();

  const {
    retrieveCurrentPortalStatus: checkPortalBlocked,
    setAlertModalVisible,
  } = usePortalStatusContext();

  const onPortalBlocked = () => {
    checkPortalBlocked();
    setAlertModalVisible(true);
  };

  return (input: R) =>
    pipe(
      apiCall.fetch({
        apiEndpoint,
        input,
        apiParameters,
        tokenInfo: authInfo,
        flowId: pipe(
          authInfo,
          option.map(d => d.flowId)
        ),
      }),
      taskEither.chainFirst(({ newTokenInfo }) =>
        taskEither.fromIO(() => pipe(newTokenInfo, option.map(refreshToken)))
      ),
      taskEither.map(({ output }) => output),
      taskEither.orElse(handleFailure(logout, onPortalBlocked)),
      config.skipTracking ? identity : trackSubmission
    );
}

type UseAPIResult<E, A> = [TaskEither<RefinedAPIError<E>, A>, () => void];
export function useAPI<E, A>(
  apiCall: APICallImplementation<void, E, A>,
  input?: void
): UseAPIResult<E, A>;
export function useAPI<R, E, A>(
  apiCall: APICallImplementation<R, E, A>,
  input: R
): UseAPIResult<E, A>;
export function useAPI<R, E, A>(
  apiCall: APICallImplementation<R, E, A>,
  input: R
): UseAPIResult<E, A> {
  const apiEndpoint = useAPIEndpoint();
  const { apiParameters } = useAppContext();
  const { authInfo, refreshToken, logout } = useAuthenticationContext();
  const [inputEquality, refreshCall] = useStableEquality(
    apiCall.inputEq,
    input
  );

  const {
    retrieveCurrentPortalStatus: checkPortalBlocked,
    setAlertModalVisible,
  } = usePortalStatusContext();

  const onPortalBlocked = () => {
    checkPortalBlocked();
    setAlertModalVisible(true);
  };

  const task = useMemo(
    () =>
      pipe(
        apiCall.fetch({
          apiEndpoint,
          input,
          apiParameters,
          tokenInfo: authInfo,
          flowId: pipe(
            authInfo,
            option.map(d => d.flowId)
          ),
        }),
        taskEither.chainFirst(({ newTokenInfo }) =>
          taskEither.fromIO(() => pipe(newTokenInfo, option.map(refreshToken)))
        ),
        taskEither.map(({ output }) => output),
        taskEither.orElse(handleFailure(logout, onPortalBlocked))
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [apiCall, inputEquality, apiEndpoint]
  );

  return [task, refreshCall];
}

export type UseQueryResult<E, A> = [
  RemoteData<RefinedAPIError<E>, A>,
  () => void
];

export function useQuery<E, A>(
  apiCall: APICallImplementation<void, E, A>,
  input?: void
): UseQueryResult<E, A>;
export function useQuery<R, E, A>(
  apiCall: APICallImplementation<R, E, A>,
  input: R
): UseQueryResult<E, A>;
export function useQuery<R, E, A>(
  apiCall: APICallImplementation<R, E, A>,
  input: R
): UseQueryResult<E, A> {
  const [task, refresh] = useAPI(apiCall, input);

  return [useRemoteData(task), refresh];
}

type PollingConfig<A> = {
  intervalMS: number;
  shouldPollingContinue: (a: A) => boolean;
};

type UsePollingResult<E, A> = UseQueryResult<E, A>;
export function usePolling<E, A>(
  apiCall: APICallImplementation<void, E, A>,
  config: PollingConfig<A>,
  input?: void
): UsePollingResult<E, A>;
export function usePolling<R, E, A>(
  apiCall: APICallImplementation<R, E, A>,
  config: PollingConfig<A>,
  input: R
): UsePollingResult<E, A>;
export function usePolling<R, E, A>(
  apiCall: APICallImplementation<R, E, A>,
  config: PollingConfig<A>,
  input: R
): UsePollingResult<E, A> {
  const [remoteResult, refresh] = useQuery(apiCall, input);
  useEffect(() => {
    if (
      pipe(
        remoteResult,
        remoteData.fold(constFalse, constTrue, config.shouldPollingContinue)
      )
    ) {
      const timeout = window.setTimeout(refresh, config.intervalMS);
      return () => window.clearTimeout(timeout);
    }
    return () => {};
  }, [remoteResult]);
  return [remoteResult, refresh];
}

type PollingEffectConfig<E, A> = PollingConfig<A> & {
  disabled?: boolean;
  onError: (error: E) => void;
  onSuccess: (result: A) => void;
};

type UsePollingEffectResult = () => void;
export function usePollingEffect<E, A>(
  apiCall: APICallImplementation<void, E, A>,
  config: PollingEffectConfig<RefinedAPIError<E>, A>,
  input?: void
): UsePollingEffectResult;
export function usePollingEffect<R, E, A>(
  apiCall: APICallImplementation<R, E, A>,
  config: PollingEffectConfig<RefinedAPIError<E>, A>,
  input: R
): UsePollingEffectResult;
export function usePollingEffect<R, E, A>(
  apiCall: APICallImplementation<R, E, A>,
  config: PollingEffectConfig<RefinedAPIError<E>, A>,
  input: R
): UsePollingEffectResult {
  const task = useCommand(apiCall, { skipTracking: true });
  const [inProgress, setInProgress] = useState(false);

  const refresh = () => {
    if (!config.disabled && !inProgress) {
      setInProgress(() => true);
      task(input)()
        .then(data => {
          setInProgress(() => false);
          const refinedData = remoteData.fromEither(data);
          pipe(
            refinedData,
            remoteData.fold(constVoid, config.onError, config.onSuccess)
          );

          if (
            pipe(
              refinedData,
              remoteData.fold(
                constFalse,
                constTrue,
                config.shouldPollingContinue
              )
            )
          ) {
            reset();
          } else {
            cancel();
          }
        })
        .catch(() => setInProgress(() => false));
    }
  };

  const [, cancel, reset] = useTimeoutFn(refresh, config.intervalMS);

  useEffect(() => {
    if (!config.disabled) {
      refresh();
    }
  }, []);
  return refresh;
}
