import { constFalse, constTrue, pipe } from "fp-ts/function";
import { foldChannel, foldChannelLocation } from "./globalDomain";
import { useAppContext } from "./useAppContext";
export function useFoldChannelLocation(): <R>(matches: {
  Remote: () => R;
  InPerson: () => R;
}) => R {
  const {
    apiParameters: { channel },
  } = useAppContext();
  return matches => pipe(channel, foldChannelLocation(matches));
}

export function useIsRemoteChannel(): boolean {
  const foldChannelToRemoteOrInPerson = useFoldChannelLocation();
  return foldChannelToRemoteOrInPerson({
    Remote: constTrue,
    InPerson: constFalse,
  });
}

export function useIsInPersonChannel(): boolean {
  const foldChannelToRemoteOrInPerson = useFoldChannelLocation();
  return foldChannelToRemoteOrInPerson({
    Remote: constFalse,
    InPerson: constTrue,
  });
}

export const useIsSBChannel: () => boolean = () => {
  const {
    apiParameters: { channel },
  } = useAppContext();
  return pipe(
    channel,
    foldChannel({
      Branch_InPerson: constFalse,
      "3P_InPerson": constFalse,
      Franchise_InPerson: constFalse,
      OB_Remote: constFalse,
      PWS_Remote: constFalse,
      SB_Remote: constTrue,
      TLS_Remote: constFalse,
    })
  );
};

export const useIs3PChannel: () => boolean = () => {
  const {
    apiParameters: { channel },
  } = useAppContext();
  return pipe(
    channel,
    foldChannel({
      Branch_InPerson: constFalse,
      "3P_InPerson": constTrue,
      Franchise_InPerson: constFalse,
      OB_Remote: constFalse,
      PWS_Remote: constFalse,
      SB_Remote: constFalse,
      TLS_Remote: constFalse,
    })
  );
};
