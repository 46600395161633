import { Option } from "fp-ts/Option";
import {
  Body,
  Box,
  Button,
  Space,
  FileIcon,
  UploadButton,
  useIsMobileLayout,
  LocalizedString,
} from "design-system";
import { constant, constVoid, pipe } from "fp-ts/function";
import { option } from "fp-ts";
import * as classes from "./UploadImageButton.treat";
import cx from "classnames";
import { useFormatMessage } from "../intl";
import { NonEmptyString } from "io-ts-types/lib/NonEmptyString";
import { useAppContext } from "../useAppContext";
import { foldTenant } from "../globalDomain";

import IdCardImage_CZ from "./idcard_cz.png";
import IdCardImage_SK from "./idcard_sk.png";
import { HelpLineSupportDocumentId } from "../Common/HelplineSupportDocumentId/HelplineSupportDocumentId";
import { useIsRemoteChannel } from "../useChannel";

type Props = {
  label: LocalizedString;
  onClick: () => unknown;
  image: Option<{
    src: NonEmptyString;
    onClick: () => unknown;
  }>;
  disabled?: boolean;
};

export function UploadImageButton(props: Props) {
  const formatMessage = useFormatMessage();

  const {
    apiParameters: { tenant },
  } = useAppContext();

  const isRemote = useIsRemoteChannel();

  const IdCardImagePlaceholder = foldTenant(
    tenant,
    () => IdCardImage_SK,
    () => IdCardImage_CZ
  );

  const isMobileLayout = useIsMobileLayout();

  return option.isSome(props.image) ? (
    <Box
      column
      grow
      shrink
      className={classes.uploadImageButton}
      data-test-id="upload-document"
    >
      <Box className={classes.imageWrapper} hAlignContent="center">
        <img
          src={pipe(
            props.image,
            option.fold(
              constant(IdCardImagePlaceholder),
              imageProp => imageProp.src
            )
          )}
          alt={props.label}
          className={cx(classes.image, {
            [classes.placeholder]: option.isNone(props.image),
          })}
        />
      </Box>

      <Box vAlignContent="center">
        <Box
          column
          grow
          vAlignContent="center"
          hAlignContent="left"
          onClick={pipe(
            props.image,
            option.fold(
              () => constVoid,
              imageProp => imageProp.onClick
            )
          )}
        >
          {pipe(
            props.image,
            option.fold(
              () => (
                <Body size="medium" weight="medium">
                  {props.label}
                </Body>
              ),
              imageProps => (
                <Button
                  label={props.label}
                  variant="text"
                  size="default"
                  icon={FileIcon}
                  action={imageProps.onClick}
                />
              )
            )
          )}
        </Box>
        <Space units={5} />
        <Button
          variant="text"
          size="default"
          action={props.onClick}
          label={formatMessage("Identification.UploadDocuments.remove")}
          data-test-id="remove_button"
          disabled={props.disabled}
        />
      </Box>
    </Box>
  ) : (
    <Box grow column>
      <UploadButton
        data-test-id="upload_button"
        uploadLabel={formatMessage("Identification.UploadDocuments.upload")}
        action={props.onClick}
        disabled={props.disabled}
      />
      {!isMobileLayout && (
        <>
          <Space units={4} />
          <Body size="xx-small" weight="regular">
            {props.label}
          </Body>
          <Space units={4} />
        </>
      )}
      {isRemote && (
        <>
          <Space units={5} />
          <HelpLineSupportDocumentId />
        </>
      )}
    </Box>
  );
}
