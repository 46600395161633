import { useContext } from "react";
import { AppContext } from "./AppContext";
import { option } from "fp-ts";

export function useAppContext() {
  const ctx = useContext(AppContext);

  if (option.isNone(ctx)) {
    throw new Error("AppContext not provided");
  }

  return ctx.value;
}
