import { ChecklistIcon, Dialog, Space } from "design-system";
import { useFormatMessage } from "../intl";
import { option } from "fp-ts";
import { HelpLineSupportDocumentId } from "../Common/HelplineSupportDocumentId/HelplineSupportDocumentId";
import { useIsRemoteChannel } from "../useChannel";

type Props = {
  onDismiss: () => unknown;
  documentMismatch: boolean;
};

export function UploadAgainDialog(props: Props) {
  const formatMessage = useFormatMessage();
  const isRemote = useIsRemoteChannel();

  return (
    <Dialog
      variant="center"
      size="medium"
      title={formatMessage(
        props.documentMismatch
          ? "Identification.UploadDocuments.dataMismatchTitle"
          : "Identification.UploadDocuments.dataWrongTitle"
      )}
      subtitle={formatMessage(
        props.documentMismatch
          ? "Identification.UploadDocuments.dataMismatchDescription"
          : "Identification.UploadDocuments.dataWrongDescription"
      )}
      onDismiss={option.some(props.onDismiss)}
      icon={ChecklistIcon}
      actions={[
        {
          variant: "primary",
          label: formatMessage("Identification.UploadDocuments.uploadAgain"),
          action: props.onDismiss,
        },
      ]}
    >
      {isRemote && (
        <>
          <Space units={5} />
          <HelpLineSupportDocumentId />
        </>
      )}
    </Dialog>
  );
}
