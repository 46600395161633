import {
  Body,
  Box,
  Button,
  Card,
  CardIcon,
  ContentRow,
  Heading,
  PageHeading,
  Space,
  Stack,
  useIsMobileLayout,
} from "design-system";
import { VirtualCards } from "./VirtualCards";
import { useFormatMessage } from "../intl";
import * as classes from "../Common/OverdraftCard/OverdraftCardDesktop.treat";
import { palette } from "design-system/lib/styleConstants";
import { Option } from "fp-ts/Option";
import { useEffect, useState } from "react";
import { BackButton } from "../Common/BackButton/BackButton";
import { IO } from "fp-ts/IO";

type Props = {
  onSubmit?: (physicalCardSelected: Option<boolean>) => void;
  physicalCardSelected: Option<boolean>;
  onChange?: (data: any) => void;
  isFromChild?: boolean;
  isCf?: boolean;
  onBack?: IO<unknown>;
  showMastercard: boolean;
};

export const VirtualCardsCommon = (props: Props) => {
  const formatMessage = useFormatMessage();
  const isMobileLayout = useIsMobileLayout();

  const [physicalCardSelected, setPhysicalCardSelected] = useState(
    props.physicalCardSelected
  );

  useEffect(() => {
    if (props.isFromChild) {
      setPhysicalCardSelected(props.physicalCardSelected);
    }
  }, [props.physicalCardSelected]);

  return (
    <>
      {!props.isCf && (
        <ContentRow type={props.isFromChild ? "full" : "lateral-margins"}>
          <Box grow shrink column hAlignContent="left">
            <PageHeading
              title={formatMessage("UKonto.MainContent.virtualCardStepTitle")}
              description={formatMessage(
                "UKonto.MainContent.virtualCardStepSubtitle"
              )}
              contentRowType={isMobileLayout ? "lateral-margins" : "full"}
            />
          </Box>
        </ContentRow>
      )}

      <ContentRow type={props.isFromChild ? "full" : "lateral-margins"}>
        <Box column shrink>
          <Card hoverHighlighted>
            <Box column shrink>
              <Stack
                units={4}
                className={classes.heading}
                vAlignContent="center"
              >
                <CardIcon size="large" monochromatic color={palette.blue800} />
                <Heading size="small" weight="medium">
                  {formatMessage(
                    props.isCf
                      ? "CF.MainContent.virtualCardStep.selectCard"
                      : "UKonto.MainContent.virtualCardStep.selectCard"
                  )}
                </Heading>
              </Stack>
              <Space units={4} />
              <Box column shrink className={classes.content}>
                <Body size="medium" weight="regular">
                  {formatMessage(
                    "UKonto.MainContent.virtualCardStep.selectCardSubtitle"
                  )}
                </Body>
                <Space units={4} />
                <VirtualCards
                  showMastercard={props.showMastercard}
                  isCf={props.isCf}
                  isFromChild={props.isFromChild}
                  physicalCardSelected={physicalCardSelected}
                  onChange={data => {
                    setPhysicalCardSelected(data.physicalCardSelected);
                    props.onChange && props.onChange(data);
                  }}
                />
              </Box>
            </Box>
          </Card>
        </Box>
      </ContentRow>

      {props.isCf && (
        <Box column>
          <Space units={4} />
          <ContentRow type="lateral-margins" grow>
            <Box grow column={isMobileLayout}>
              <Box>
                <BackButton action={() => props.onBack && props.onBack()} />
              </Box>
              <Box
                column
                grow
                style={{ alignItems: "end" }}
                hAlignContent="right"
              >
                <Button
                  variant="primary"
                  size="default"
                  action={() =>
                    props.onSubmit && props.onSubmit(physicalCardSelected)
                  }
                  label={formatMessage("Next")}
                />
              </Box>
            </Box>
          </ContentRow>
        </Box>
      )}
    </>
  );
};
