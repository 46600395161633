import * as t from "io-ts";
import { NonEmptyString } from "io-ts-types/lib/NonEmptyString";
import { FileContent } from "design-system";
import { optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import { optionFromUndefined } from "../globalDomain";

export const DocumentType = t.keyof(
  {
    IDCard: true,
    Passport: true,
    DrivingLicense: true,
    PermanentResidencePermit: true,
    LongTermResidencePermit: true,
    TemporaryResidencePermit: true,
  },
  "DocumentType"
);
export type DocumentType = t.TypeOf<typeof DocumentType>;

export type DocumentTypeUpload =
  | Exclude<DocumentType, "DrivingLicense">
  | "DrivingLicenseCZ"
  | "DrivingLicenseSK";

export type DocumentObject = {
  src: string;
  type: DocumentTypeUpload;
};

export const MobileRecipientType = t.keyof({
  Banker: true,
  Client: true,
});
export type MobileRecipientType = t.TypeOf<typeof MobileRecipientType>;

export const MobileRecipientNumber = t.type({
  phoneNumber: optionFromNullable(NonEmptyString),
  disabled: t.boolean,
});
export type MobileRecipientNumber = t.TypeOf<typeof MobileRecipientNumber>;

export const MobileStatus = t.keyof({
  WaitingForConnection: true,
  MobileConnected: true,
  UploadInProgress: true,
  UploadCompleted: true,
  ConnectionLost: true,
  MustEditData: true,
  VerificationCompleted: true,
});
export type MobileStatus = t.TypeOf<typeof MobileStatus>;

export const DocumentPage = t.keyof(
  { Front: true, Back: true },
  "DocumentPage"
);
export type DocumentPage = t.TypeOf<typeof DocumentPage>;

export const DocumentSlot = t.keyof(
  { First: true, Second: true },
  "DocumentSlot"
);
export type DocumentSlot = t.TypeOf<typeof DocumentSlot>;

export const IdDocument = t.type(
  {
    fileContent: NonEmptyString,
    documentType: DocumentType,
    documentPage: DocumentPage,
  },
  "IdDocument"
);
export type IdDocument = t.TypeOf<typeof IdDocument>;

export const ScannedDocument = t.type(
  {
    base64: FileContent,
    fileName: NonEmptyString,
  },
  "ScannedDocument"
);
export type ScannedDocument = t.TypeOf<typeof ScannedDocument>;

export const ClientDataCheck = t.keyof({
  SelfieFraudCheck: true,
  HologramFraudCheck: true,
  HologramAndSelfieFraudCheck: true,
});
export type ClientDataCheck = t.TypeOf<typeof ClientDataCheck>;

export const LinkPurpose = t.keyof({
  selfieFraudCheck: true,
  hologramFraudCheck: true,
  hologramAndSelfieFraudCheck: true,
  mobileIdUpload: true,
  proofOfIncome: true,
});
export type LinkPurpose = t.TypeOf<typeof LinkPurpose>;

export const SealingStatus = t.keyof({
  unsealed: true,
  sealing: true,
  sealed: true,
});

export const Country = t.type({
  label: t.string,
  countryCode: NonEmptyString,
  inEuropeanUnion: t.boolean,
});
export type Country = t.TypeOf<typeof Country>;

export const DocumentToUploadDetail = t.type({
  country: Country,
  type: optionFromUndefined(DocumentType),
});
export type DocumentToUploadDetail = t.TypeOf<typeof DocumentToUploadDetail>;
