import { useCallback, useEffect, useState } from "react";
import { pipe } from "fp-ts/function";
import { taskEither } from "fp-ts";
import { getCurrentBuildVersion } from "./Utils";
import { useAppContext } from "../useAppContext";
import { useIsInPersonChannel } from "../useChannel";
import { useTestEnvironment } from "../Common/useTestEnvironment";

export function useVersionCheck() {
  const { buildVersion: runningVersion } = useAppContext();

  const inPersonChannel = useIsInPersonChannel();

  const [showAlert, setShowAlert] = useState(false);
  const [dismissed, setDismissed] = useState(false);

  const [state, updateState] = useState<unknown>();
  const forceUpdate = useCallback(() => updateState({}), []);

  const onBuildVersionReceived = (currentAvailableVersion: string) => {
    const areVersionsDifferent =
      currentAvailableVersion.length > 0 &&
      runningVersion !== currentAvailableVersion;

    if (areVersionsDifferent) {
      setShowAlert(true);
      console.log(
        "VersionChecker different UI version detected, using: " +
          runningVersion.trim() +
          " new: " +
          currentAvailableVersion.trim()
      );
    }
  };

  useEffect(() => {
    pipe(
      taskEither.tryCatch(getCurrentBuildVersion, () => {
        console.log("Rejected retrieving build version");
        return "";
      }),
      taskEither.bimap(() => onBuildVersionReceived(""), onBuildVersionReceived)
    )();
  }, [state]);

  const isTestEnvironment = useTestEnvironment();

  const shouldShowNewVersionAlert =
    isTestEnvironment && inPersonChannel && showAlert && !dismissed;
  const onCancelAlert = () => {
    setShowAlert(false);
    setDismissed(true);
  };
  const reset = () => {
    setShowAlert(false);
    forceUpdate();
  };

  return { shouldShowNewVersionAlert, onCancelAlert, reset };
}
